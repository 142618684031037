const data = [
  {
    name: "Kaushikee Das",
    class: 1,
    score: "33/35",
    orank: 1,
    srank: 1,
    roll: "AM869101100",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "AAYANSH MISHRA",
    class: 1,
    score: "34/35",
    orank: 3,
    srank: 1,
    roll: "AT869101410",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "subha",
    class: 1,
    score: "32/35",
    orank: 5,
    srank: 1,
    roll: "AR869101073",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Anwesha Dalai",
    class: 1,
    score: "34/35",
    orank: 5,
    srank: 1,
    roll: "AS869101025",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Swayampoorna Mishra",
    class: 1,
    score: "33/35",
    orank: 5,
    srank: 1,
    roll: "AT869101103",
    school: "MBS Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "AAYANSH MISHRA",
    class: 1,
    score: "27/35",
    orank: 36,
    srank: 1,
    roll: "AM869101901",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "AAYANSH MISHRA",
    class: 1,
    score: "29/35",
    orank: 45,
    srank: 1,
    roll: "SC869101305",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Rudransh Routray",
    class: 1,
    score: "26/35",
    orank: 50,
    srank: 1,
    roll: "AR869101532",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Ashneel Sahoo",
    class: 1,
    score: "32/35",
    orank: 2,
    srank: 2,
    roll: "AM869101103",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "AAYANSH MISHRA",
    class: 1,
    score: "33/35",
    orank: 3,
    srank: 2,
    roll: "AR869101533",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Sai Sworup Parida",
    class: 1,
    score: "31/35",
    orank: 9,
    srank: 2,
    roll: "AR869101072",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Akshita Sahu",
    class: 1,
    score: "32/35",
    orank: 12,
    srank: 2,
    roll: "AT869101102",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Aarav Kumar",
    class: 1,
    score: "32/35",
    orank: 15,
    srank: 2,
    roll: "AS869101027",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Rudransh Routray",
    class: 1,
    score: "31/35",
    orank: 24,
    srank: 2,
    roll: "AT869101409",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Rudransh Routray",
    class: 1,
    score: "26/35",
    orank: 57,
    srank: 2,
    roll: "AM869101913",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Rudransh Routray",
    class: 1,
    score: "27/35",
    orank: 69,
    srank: 2,
    roll: "SC869101304",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Pritiparna Panda",
    class: 1,
    score: "32/35",
    orank: 2,
    srank: 3,
    roll: "AM869101122",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Jaikrish Das",
    class: 1,
    score: "30/35",
    orank: 11,
    srank: 3,
    roll: "AR869101071",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Sweta Saswati",
    class: 1,
    score: "30/35",
    orank: 25,
    srank: 3,
    roll: "AS869101026",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Sriya Rani Panda",
    class: 1,
    score: "30/35",
    orank: 36,
    srank: 3,
    roll: "AT869101098",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "17/35",
    orank: 256,
    srank: 3,
    roll: "SC869101308",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "ayushman",
    class: 1,
    score: "17/35",
    orank: 432,
    srank: 3,
    roll: "AT869101415",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Rajkanya Swain",
    class: 1,
    score: "31/35",
    orank: 4,
    srank: 4,
    roll: "AM869101090",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Ankita Alladin",
    class: 1,
    score: "30/35",
    orank: 25,
    srank: 4,
    roll: "AS869101030",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bhagyalami sahoo",
    class: 1,
    score: "29/35+D541",
    orank: 29,
    srank: 4,
    roll: "AR869101069",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Anwesh Kumar Pradhan",
    class: 1,
    score: "29/35",
    orank: 50,
    srank: 4,
    roll: "AT869101107",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Rishiraj nayak",
    class: 1,
    score: "13/35",
    orank: 453,
    srank: 4,
    roll: "SC869101315",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "31/35",
    orank: 4,
    srank: 5,
    roll: "AM869101099",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Bishal Kumar Bal",
    class: 1,
    score: "30/35",
    orank: 25,
    srank: 5,
    roll: "AS869101039",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Pathi Deeksha",
    class: 1,
    score: "29/35",
    orank: 29,
    srank: 5,
    roll: "AR869101074",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Mivaam Dash",
    class: 1,
    score: "28/35",
    orank: 61,
    srank: 5,
    roll: "AT869101100",
    school: "Mothers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "ayushman",
    class: 1,
    score: "Nov-35",
    orank: 599,
    srank: 5,
    roll: "SC869101310",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Laxmipriya Pani",
    class: 1,
    score: "30/35",
    orank: 6,
    srank: 6,
    roll: "AM869101080",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Adyasha Mangaraj",
    class: 1,
    score: "30/35",
    orank: 25,
    srank: 6,
    roll: "AS869101040",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Vanhishikha sa",
    class: 1,
    score: "29/35",
    orank: 29,
    srank: 6,
    roll: "AR869101076",
    school: "St. Xaviers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Devaprasad Parida",
    class: 1,
    score: "25/35",
    orank: 114,
    srank: 6,
    roll: "AT869101101",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Satyajeet Singh",
    class: 1,
    score: "30/35",
    orank: 6,
    srank: 7,
    roll: "AM869101106",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Hitusmita Naik",
    class: 1,
    score: "28/35",
    orank: 38,
    srank: 7,
    roll: "AR869101070",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ghatak Sethi",
    class: 1,
    score: "29/35",
    orank: 45,
    srank: 7,
    roll: "AS869101038",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aradhya Balabantaray",
    class: 1,
    score: "23/35",
    orank: 213,
    srank: 7,
    roll: "AT869101099",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Rituparna Naik",
    class: 1,
    score: "30/35",
    orank: 6,
    srank: 8,
    roll: "AM869101121",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Deepanwita Jena",
    class: 1,
    score: "29/35",
    orank: 45,
    srank: 8,
    roll: "AS869101042",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Stiti Sampoorna",
    class: 1,
    score: "27/35",
    orank: 47,
    srank: 8,
    roll: "AR869101068",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Dibyansi Raul",
    class: 1,
    score: "17/35",
    orank: 432,
    srank: 8,
    roll: "AT869101106",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Adik Ayan",
    class: 1,
    score: "29/35",
    orank: 9,
    srank: 9,
    roll: "AM869101094",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Chhanda Madhuri sahu",
    class: 1,
    score: "27/35",
    orank: 47,
    srank: 9,
    roll: "AR869101075",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "M.Deva Harsha",
    class: 1,
    score: "28/35",
    orank: 50,
    srank: 9,
    roll: "AS869101037",
    school: "St. Xaviers International",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "G. Srikar",
    class: 1,
    score: "16/35",
    orank: 498,
    srank: 9,
    roll: "AT869101105",
    school: "Kendriya Vidyalaya No 3",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sarthak Biswal",
    class: 1,
    score: "29/35",
    orank: 9,
    srank: 10,
    roll: "AM869101098",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Pritisha Samantasinhar",
    class: 1,
    score: "26/35",
    orank: 50,
    srank: 10,
    roll: "AR869101067",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aditya Narayan",
    class: 1,
    score: "28/35",
    orank: 50,
    srank: 10,
    roll: "AS869101041",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "N. Glory",
    class: 1,
    score: "12/35+D1019",
    orank: 555,
    srank: 10,
    roll: "AT869101104",
    school: "Mothers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ksheerabdhi Tanaya Rath",
    class: 1,
    score: "29/35",
    orank: 9,
    srank: 11,
    roll: "AM869101108",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bidushmita Nayak",
    class: 1,
    score: "27/35",
    orank: 69,
    srank: 11,
    roll: "AS869101029",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Roshni Ruhansika Panda",
    class: 1,
    score: "17/35",
    orank: 345,
    srank: 11,
    roll: "AR869101066",
    school: "Narayana Techno School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Itishree Behera",
    class: 1,
    score: "28/35",
    orank: 19,
    srank: 12,
    roll: "AM869101095",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shradha Suman Samal",
    class: 1,
    score: "24/35",
    orank: 111,
    srank: 12,
    roll: "AS869101023",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Swayam Prakash",
    class: 1,
    score: "28/35",
    orank: 19,
    srank: 13,
    roll: "AM869101107",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Amrutansh Pani",
    class: 1,
    score: "24/35+D794",
    orank: 111,
    srank: 13,
    roll: "AS869101036",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ishita Manna",
    class: 1,
    score: "28/35",
    orank: 19,
    srank: 14,
    roll: "AM869101120",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Priyanshi Priyadarshini Gouda",
    class: 1,
    score: "Feb-35",
    orank: 156,
    srank: 14,
    roll: "AS869101024",
    school: "Morning Glory School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ajitesh Singh Chauhan",
    class: 1,
    score: "27/35",
    orank: 36,
    srank: 15,
    roll: "AM869101096",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Manish kumar Baskey",
    class: 1,
    score: "23/35",
    orank: 156,
    srank: 15,
    roll: "AS869101035",
    school: "Hightech Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Saisampurna Behera",
    class: 1,
    score: "27/35",
    orank: 36,
    srank: 16,
    roll: "AM869101097",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sunyabasi Behera",
    class: 1,
    score: "18/35",
    orank: 248,
    srank: 16,
    roll: "AS869101034",
    school: "Kiit International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Gilda Ruby Singh",
    class: 1,
    score: "27/35",
    orank: 36,
    srank: 17,
    roll: "AM869101102",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Priyanka Dakua",
    class: 1,
    score: "16/35",
    orank: 267,
    srank: 17,
    roll: "AS869101033",
    school: "Morning Glory School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "M.Yogesh",
    class: 1,
    score: "27/35",
    orank: 36,
    srank: 18,
    roll: "AM869101115",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Adarsa Behera",
    class: 1,
    score: "15/35",
    orank: 342,
    srank: 18,
    roll: "AS869101032",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Jeevanjyoti Mohanta",
    class: 1,
    score: "26/35",
    orank: 57,
    srank: 19,
    roll: "AM869101083",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Satvik Sabat",
    class: 1,
    score: "13/35",
    orank: 453,
    srank: 19,
    roll: "AS869101031",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Sanjeev Badajena",
    class: 1,
    score: "26/35",
    orank: 57,
    srank: 20,
    roll: "AM869101114",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "T.V Jhanish Kumar",
    class: 1,
    score: "Dec-35",
    orank: 567,
    srank: 20,
    roll: "AS869101028",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Divyansh Panda",
    class: 1,
    score: "25/35",
    orank: 123,
    srank: 21,
    roll: "AM869101088",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Suvan Nath",
    class: 1,
    score: "25/35",
    orank: 123,
    srank: 22,
    roll: "AM869101113",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "P. Akshita",
    class: 1,
    score: "25/35",
    orank: 123,
    srank: 23,
    roll: "AM869101116",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Somesh Nath Sharma",
    class: 1,
    score: "24/35",
    orank: 178,
    srank: 24,
    roll: "AM869101082",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Toyesh Padhan",
    class: 1,
    score: "24/35",
    orank: 178,
    srank: 25,
    roll: "AM869101089",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Prajyot Plaban Mohanty",
    class: 1,
    score: "23/35",
    orank: 267,
    srank: 26,
    roll: "AM869101079",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Dharashree Naik",
    class: 1,
    score: "23/35",
    orank: 267,
    srank: 27,
    roll: "AM869101117",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Smruti Ranjan Biswal",
    class: 1,
    score: "23/35",
    orank: 267,
    srank: 28,
    roll: "AM869101124",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Adyasha Mohapatra",
    class: 1,
    score: "22/35",
    orank: 321,
    srank: 29,
    roll: "AM869101081",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pritam Das",
    class: 1,
    score: "21/35",
    orank: 345,
    srank: 30,
    roll: "AM869101087",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ashriwad Thatoe",
    class: 1,
    score: "21/35",
    orank: 345,
    srank: 31,
    roll: "AM869101123",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Kanishq Kumar",
    class: 1,
    score: "20/35",
    orank: 378,
    srank: 32,
    roll: "AM869101091",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Amiya Jena",
    class: 1,
    score: "20/35",
    orank: 378,
    srank: 33,
    roll: "AM869101910",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Himadri Sasmal",
    class: 1,
    score: "19/35",
    orank: 432,
    srank: 34,
    roll: "AM869101086",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pratyusha Das",
    class: 1,
    score: "19/35",
    orank: 432,
    srank: 35,
    roll: "AM869101101",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Subrojit Parida",
    class: 1,
    score: "19/35",
    orank: 467,
    srank: 36,
    roll: "AM869101105",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shanvi Padhi",
    class: 1,
    score: "19/35",
    orank: 467,
    srank: 37,
    roll: "AM869101112",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Aratrika Bhuyan",
    class: 1,
    score: "18/35",
    orank: 532,
    srank: 38,
    roll: "AM869101093",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Anwesha Nanda",
    class: 1,
    score: "17/35",
    orank: 521,
    srank: 39,
    roll: "AM869101104",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ritika Mishra",
    class: 1,
    score: "17/35",
    orank: 521,
    srank: 40,
    roll: "AM869101111",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pulakesh Singh",
    class: 1,
    score: "16/35",
    orank: 612,
    srank: 41,
    roll: "AM869101119",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shreeyanshi Acharya",
    class: 1,
    score: "14/35",
    orank: 698,
    srank: 42,
    roll: "AM869101092",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Devansh Rath",
    class: 1,
    score: "14/35",
    orank: 698,
    srank: 43,
    roll: "AM869101110",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Monisha Pradhan",
    class: 1,
    score: "13/35",
    orank: 786,
    srank: 44,
    roll: "AM869101118",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Snehal Maharana",
    class: 1,
    score: "12/35",
    orank: 811,
    srank: 45,
    roll: "AM869101078",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Suneet Ku. Sahoo",
    class: 1,
    score: "12/35",
    orank: 811,
    srank: 46,
    roll: "AM869101084",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Swayam Kumar Nanda",
    class: 1,
    score: "12/35",
    orank: 811,
    srank: 47,
    roll: "AM869101109",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Yashraj Sethi",
    class: 1,
    score: "13/35",
    orank: 876,
    srank: 48,
    roll: "AM869101085",
    school: "St. Xaviers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Aran Nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101902",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "ayushman Behera",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101903",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Divyansh Dash",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101904",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "farzeen Fathema",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101905",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Jaydev Sethi",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101906",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Jeet Aditeya Dash",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101907",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Lipsa Acharya",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101908",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101909",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Reyansh Ray",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101911",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rishiraj nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101912",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Saloni Nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869101914",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Lipsa Acharya",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101534",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101535",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101536",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "jeet",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101537",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "ayushman",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101538",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "farzeen",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101539",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Divyansh",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101540",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aran",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101541",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Jaydev",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101542",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rishiraj nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101543",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Saloni Nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869101544",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Lipsa Acharya",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101306",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101307",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "jeet",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101309",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "farzeen",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101311",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Divyansh",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101312",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Aran",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101313",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Jaydev",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101314",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Saloni Nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869101316",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Lipsa Acharya",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101411",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101412",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101413",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "jeet",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101414",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "farzeen",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101416",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Divyansh",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101417",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Aran",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101418",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Jaydev",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101419",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Rishiraj nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101420",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Saloni Nayak",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869101421",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Mohammed shahenoor",
    class: 1,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "kv 1",
    school: "Kendriya Vidyalaya No1",
    exam: "",
    prize: "",
  },
  {
    name: "Om Prasad Jena",
    class: 2,
    score: "35/35",
    orank: 1,
    srank: 1,
    roll: "AM869102009",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Pabitra Kumar Sahoo",
    class: 2,
    score: "35/35",
    orank: 1,
    srank: 1,
    roll: "AR869102112",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Rama chandra Naik",
    class: 2,
    score: "35/35",
    orank: 1,
    srank: 1,
    roll: "AS869102110",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Sabyasachi Pradhan",
    class: 2,
    score: "34/35",
    orank: 3,
    srank: 1,
    roll: "AT869102020",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Arnav Kunar",
    class: 2,
    score: "31/35",
    orank: 34,
    srank: 1,
    roll: "SC869102531",
    school: "Sai International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Nachiket Ghadei",
    class: 2,
    score: "28/35",
    orank: 69,
    srank: 1,
    roll: "AT869102744",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Arnav Kunar",
    class: 2,
    score: "27/35",
    orank: 112,
    srank: 1,
    roll: "AM869102789",
    school: "Sai International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "sai shankalp",
    class: 2,
    score: "16/35",
    orank: 345,
    srank: 1,
    roll: "AR869102700",
    school: "lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Abani kumar sahoo",
    class: 2,
    score: "Dec-35",
    orank: 488,
    srank: 1,
    roll: "AT869102015",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Sishu Ranjan Das",
    class: 2,
    score: "35/35",
    orank: 1,
    srank: 2,
    roll: "AM869102036",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Balabhadra Naik",
    class: 2,
    score: "33/35",
    orank: 4,
    srank: 2,
    roll: "AR869102113",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Silver Medal",
  },
  {
    name: "P. Shyam Sundar Achary",
    class: 2,
    score: "34/35",
    orank: 7,
    srank: 2,
    roll: "AS869102109",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Prasant Mangaraj",
    class: 2,
    score: "33/35",
    orank: 7,
    srank: 2,
    roll: "AT869102008",
    school: "Happy Hours School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Aishani Nanda",
    class: 2,
    score: "25/35",
    orank: 178,
    srank: 2,
    roll: "AM869102785",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Akankshya Mishra",
    class: 2,
    score: "25/35",
    orank: 298,
    srank: 2,
    roll: "SC869102544",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Ruchir Nayak",
    class: 2,
    score: "19/35",
    orank: 298,
    srank: 2,
    roll: "AT869102745",
    school: "Sai International School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Prabodha kumar Panda",
    class: 2,
    score: "34/35",
    orank: 3,
    srank: 3,
    roll: "AM869102037",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Bronze Medal",
  },
  {
    name: "Rabindra Kumar Acharya",
    class: 2,
    score: "33/35",
    orank: 4,
    srank: 3,
    roll: "AR869102119",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Bronze Medal",
  },
  {
    name: "Sangram keshari Panda",
    class: 2,
    score: "33/35",
    orank: 7,
    srank: 3,
    roll: "AT869102021",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "M.  Ganesh",
    class: 2,
    score: "33/35",
    orank: 14,
    srank: 3,
    roll: "AS869102108",
    school: "St. Xaviers International",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Aniket Aryaveer Dora",
    class: 2,
    score: "23/35",
    orank: 209,
    srank: 3,
    roll: "AM869102787",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Ruchir Nayak",
    class: 2,
    score: "24/35",
    orank: 321,
    srank: 3,
    roll: "SC869102535",
    school: "Sai International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "sai shankalp",
    class: 2,
    score: "17/35",
    orank: 354,
    srank: 3,
    roll: "AT869102749",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Priya Ranjan Sasmal",
    class: 2,
    score: "34/35",
    orank: 3,
    srank: 4,
    roll: "AM869102035",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Bronze Medal",
  },
  {
    name: "Prasanta kumar Das",
    class: 2,
    score: "32/35",
    orank: 12,
    srank: 4,
    roll: "AR869102114",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bikramaditya Bal",
    class: 2,
    score: "32/35",
    orank: 13,
    srank: 4,
    roll: "AT869102007",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Malaya kumar Nanda",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 4,
    roll: "AS869102102",
    school: "St. Xaviers International",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Akankshya Mishra",
    class: 2,
    score: "22/35",
    orank: 234,
    srank: 4,
    roll: "AM869102801",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rajveer Parida",
    class: 2,
    score: "23/35",
    orank: 387,
    srank: 4,
    roll: "SC869102537",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Vidyanshu Naik",
    class: 2,
    score: "34/35",
    orank: 3,
    srank: 5,
    roll: "AM869102022",
    school: "St. Xaviers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Bronze Medal",
  },
  {
    name: "Tara Prasad Rath",
    class: 2,
    score: "32/35",
    orank: 12,
    srank: 5,
    roll: "AR869102120",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ramachandra Behera",
    class: 2,
    score: "32/35",
    orank: 13,
    srank: 5,
    roll: "AT869102009",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sangram keshari Badajena",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 5,
    roll: "AS869102107",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sai shankalp Mohanty",
    class: 2,
    score: "20/35+D142",
    orank: 345,
    srank: 5,
    roll: "AM869102798",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "sai shankalp",
    class: 2,
    score: "13/35",
    orank: 651,
    srank: 5,
    roll: "SC869102539",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Laxman Sethi",
    class: 2,
    score: "33/35+D116",
    orank: 6,
    srank: 6,
    roll: "AM869102034",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Aurobind Samanta Sinhar",
    class: 2,
    score: "32/35",
    orank: 13,
    srank: 6,
    roll: "AT869102022",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Hrudananda Nayak",
    class: 2,
    score: "31/35",
    orank: 17,
    srank: 6,
    roll: "AR869102111",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Atanu kumar Pradhan",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 6,
    roll: "AS869102111",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aniket Pradhan",
    class: 2,
    score: "15/35",
    orank: 678,
    srank: 6,
    roll: "AM869102788",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ruchir Nayak",
    class: 2,
    score: "15/35",
    orank: 678,
    srank: 6,
    roll: "AM869102796",
    school: "Sai International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Mangal Patra",
    class: 2,
    score: "33/35",
    orank: 6,
    srank: 7,
    roll: "AM869102020",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Ratikanta Sa",
    class: 2,
    score: "31/35",
    orank: 17,
    srank: 7,
    roll: "AR869102118",
    school: "Narayana Techno School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bibhu Ranjan Dalai",
    class: 2,
    score: "32/35+D823",
    orank: 19,
    srank: 7,
    roll: "AS869102119",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Prakash Sethi",
    class: 2,
    score: "31/35",
    orank: 24,
    srank: 7,
    roll: "AT869102006",
    school: "Happy Hours School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Saumyashree Rath",
    class: 2,
    score: "33/35",
    orank: 6,
    srank: 8,
    roll: "AM869102011",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sanat Sujat Mandal",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 8,
    roll: "AS869102120",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sushil kumar Bag",
    class: 2,
    score: "29/35",
    orank: 45,
    srank: 8,
    roll: "AR869102122",
    school: "Vikash Public school",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Santanu Jena",
    class: 2,
    score: "30/35",
    orank: 45,
    srank: 8,
    roll: "AT869102010",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Chinmaya Kumar Sahoo",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 9,
    roll: "AM869102033",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Jatindra kumar Nayak",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 9,
    roll: "AS869102123",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Prasanta kumar Sahu",
    class: 2,
    score: "27/35",
    orank: 57,
    srank: 9,
    roll: "AR869102117",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Umesh Chandra Panda",
    class: 2,
    score: "24/35",
    orank: 113,
    srank: 9,
    roll: "AT869102011",
    school: "St. Xaviers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Gopinath Pradhan",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 10,
    roll: "AM869102038",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shyamanand Rath",
    class: 2,
    score: "31/35",
    orank: 34,
    srank: 10,
    roll: "AS869102103",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sk. MD. Rizwan",
    class: 2,
    score: "27/35",
    orank: 57,
    srank: 10,
    roll: "AR869102121",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Santosh Balabantaray",
    class: 2,
    score: "22/35",
    orank: 178,
    srank: 10,
    roll: "AT869102012",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Lipsa Acharya",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 11,
    roll: "AM869102010",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Manoranjan Nath",
    class: 2,
    score: "31/35",
    orank: 34,
    srank: 11,
    roll: "AS869102106",
    school: "Hightech Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Rabi chandra Patra",
    class: 2,
    score: "27/35",
    orank: 57,
    srank: 11,
    roll: "AR869102126",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sujit kumar Dash",
    class: 2,
    score: "21/35",
    orank: 199,
    srank: 11,
    roll: "AT869102013",
    school: "Kiit International School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Om Sai Kumar Behera",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 12,
    roll: "AM869102023",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Rupesh Kumar",
    class: 2,
    score: "31/35",
    orank: 34,
    srank: 12,
    roll: "AS869102121",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Lalat keshari Pradhan",
    class: 2,
    score: "26/35",
    orank: 89,
    srank: 12,
    roll: "AR869102125",
    school: "St. Xaviers International",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Biplab Keshari Parida",
    class: 2,
    score: "20/35",
    orank: 219,
    srank: 12,
    roll: "AT869102014",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Pratyusha Samal",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 13,
    roll: "AM869102021",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tapas kumar Singh",
    class: 2,
    score: "30/35",
    orank: 50,
    srank: 13,
    roll: "AS869102100",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "P. Dharma Rao",
    class: 2,
    score: "25/35+D566",
    orank: 102,
    srank: 13,
    roll: "AR869102116",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Umakanta Raul",
    class: 2,
    score: "19/35",
    orank: 298,
    srank: 13,
    roll: "AT869102019",
    school: "St. Xaviers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Vimlesh Singh Chauhan",
    class: 2,
    score: "32/35",
    orank: 19,
    srank: 14,
    roll: "AM869102045",
    school: "St. Xaviers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ramesh Mishra",
    class: 2,
    score: "30/35",
    orank: 50,
    srank: 14,
    roll: "AS869102104",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Simadri Sekhar Parida",
    class: 2,
    score: "24/35",
    orank: 145,
    srank: 14,
    roll: "AR869102115",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "G. Anand",
    class: 2,
    score: "17/35",
    orank: 354,
    srank: 14,
    roll: "AT869102018",
    school: "Kendriya Vidyalaya No 3",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Kumar Janmejoy Mohanta",
    class: 2,
    score: "31/35",
    orank: 34,
    srank: 15,
    roll: "AM869102032",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tukuna Padhi",
    class: 2,
    score: "30/35",
    orank: 50,
    srank: 15,
    roll: "AS869102105",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Niharendu Das",
    class: 2,
    score: "24/35",
    orank: 145,
    srank: 15,
    roll: "AR869102123",
    school: "Mothers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "N. Nagaraju",
    class: 2,
    score: "16/35",
    orank: 397,
    srank: 15,
    roll: "AT869102017",
    school: "Mothers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Anil Kumar Mohapatra",
    class: 2,
    score: "30/35",
    orank: 37,
    srank: 16,
    roll: "AM869102030",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "T.Jagan Mohan Swami",
    class: 2,
    score: "30/35",
    orank: 50,
    srank: 16,
    roll: "AS869102122",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tapas kumar Panda",
    class: 2,
    score: "23/35",
    orank: 198,
    srank: 16,
    roll: "AR869102124",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Prahllad Kumar Mishra",
    class: 2,
    score: "13/35",
    orank: 456,
    srank: 16,
    roll: "AT869102016",
    school: "Happy Hours School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Kanhu Charan Nath",
    class: 2,
    score: "30/35",
    orank: 37,
    srank: 17,
    roll: "AM869102031",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Satya Narayan Parida",
    class: 2,
    score: "29/35",
    orank: 87,
    srank: 17,
    roll: "AS869102099",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Basudev Pani",
    class: 2,
    score: "29/35",
    orank: 45,
    srank: 18,
    roll: "AM869102029",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Hara prasad Gouda",
    class: 2,
    score: "29/35",
    orank: 87,
    srank: 18,
    roll: "AS869102118",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Rajnandini Ojha",
    class: 2,
    score: "29/35",
    orank: 45,
    srank: 19,
    roll: "AM869102025",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Gyana Ranjan Samal",
    class: 2,
    score: "28/35",
    orank: 101,
    srank: 19,
    roll: "AS869102117",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ranjan Behera",
    class: 2,
    score: "29/35",
    orank: 45,
    srank: 20,
    roll: "AM869102044",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Jitray Baskey",
    class: 2,
    score: "28/35",
    orank: 101,
    srank: 20,
    roll: "AS869102129",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Siddheswari Swayamsidda",
    class: 2,
    score: "29/35",
    orank: 45,
    srank: 21,
    roll: "AM869102014",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Gyanraj Singh",
    class: 2,
    score: "27/35",
    orank: 176,
    srank: 21,
    roll: "AS869102098",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Lal Mohan Sahoo",
    class: 2,
    score: "28/35",
    orank: 56,
    srank: 22,
    roll: "AM869102043",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Santosh Kumar Biswal",
    class: 2,
    score: "27/35",
    orank: 176,
    srank: 22,
    roll: "AS869102116",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Pranab kishore Mohanty",
    class: 2,
    score: "28/35",
    orank: 56,
    srank: 23,
    roll: "AM869102028",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sahadev Behera",
    class: 2,
    score: "27/35",
    orank: 176,
    srank: 23,
    roll: "AS869102128",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sanjay Behera",
    class: 2,
    score: "28/35",
    orank: 56,
    srank: 24,
    roll: "AM869102046",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Arun kumar Thatoi",
    class: 2,
    score: "26/35",
    orank: 234,
    srank: 24,
    roll: "AS869102115",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Arnab",
    class: 2,
    score: "27/35",
    orank: 112,
    srank: 25,
    roll: "AM869102024",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Jitendra Dakua",
    class: 2,
    score: "26/35",
    orank: 234,
    srank: 25,
    roll: "AS869102127",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Dhiraj Kumar Bhuyan",
    class: 2,
    score: "27/35",
    orank: 112,
    srank: 26,
    roll: "AM869102042",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Jagadananda Panda",
    class: 2,
    score: "25/35",
    orank: 298,
    srank: 26,
    roll: "AS869102114",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Name of the student",
    class: 2,
    score: "27/35",
    orank: 112,
    srank: 27,
    roll: "AM869102013",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rajib Manna",
    class: 2,
    score: "24/35",
    orank: 321,
    srank: 27,
    roll: "AS869102113",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Rituparna Das",
    class: 2,
    score: "27/35",
    orank: 112,
    srank: 28,
    roll: "AM869102015",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pravat Ranjan Das",
    class: 2,
    score: "23/35",
    orank: 387,
    srank: 28,
    roll: "AS869102097",
    school: "Happy Hours School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ranjan Kumar Maharana",
    class: 2,
    score: "26/35",
    orank: 156,
    srank: 29,
    roll: "AM869102027",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pitabash Behera",
    class: 2,
    score: "23/35",
    orank: 387,
    srank: 29,
    roll: "AS869102101",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Swaraj Kumar Gupta",
    class: 2,
    score: "26/35",
    orank: 156,
    srank: 30,
    roll: "AM869102026",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Alok kumar Behera",
    class: 2,
    score: "23/35",
    orank: 387,
    srank: 30,
    roll: "AS869102126",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Soumya Ranjan Acharya",
    class: 2,
    score: "25/35",
    orank: 178,
    srank: 31,
    roll: "AM869102041",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nitya Sundar Das",
    class: 2,
    score: "22/35",
    orank: 465,
    srank: 31,
    roll: "AS869102096",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Tridib Panda",
    class: 2,
    score: "25/35",
    orank: 178,
    srank: 32,
    roll: "AM869102016",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Tapan kumar Dash",
    class: 2,
    score: "22/35",
    orank: 465,
    srank: 32,
    roll: "AS869102124",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Chinmaya Biswal",
    class: 2,
    score: "24/35",
    orank: 178,
    srank: 33,
    roll: "AM869102047",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pravat kumar Singh",
    class: 2,
    score: "21/35",
    orank: 500,
    srank: 33,
    roll: "AS869102112",
    school: "Happy Hours School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Afifa Rizwan",
    class: 2,
    score: "23/35",
    orank: 209,
    srank: 34,
    roll: "AM869102012",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sanjay Sabat",
    class: 2,
    score: "21/35",
    orank: 500,
    srank: 34,
    roll: "AS869102125",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Anil Barik",
    class: 2,
    score: "23/35",
    orank: 209,
    srank: 35,
    roll: "AM869102040",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Dharitri Tanaya Panda",
    class: 2,
    score: "23/35",
    orank: 209,
    srank: 36,
    roll: "AM869102019",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pravat Kumar Behera",
    class: 2,
    score: "22/35",
    orank: 234,
    srank: 37,
    roll: "AM869102048",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ritika Rani Patra",
    class: 2,
    score: "22/35",
    orank: 234,
    srank: 38,
    roll: "AM869102018",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Priyanshu Pradhan",
    class: 2,
    score: "21/35",
    orank: 267,
    srank: 39,
    roll: "AM869102017",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Smruti Ranjan Swain",
    class: 2,
    score: "21/35",
    orank: 267,
    srank: 40,
    roll: "AM869102039",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Angel Fransis Nayak",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102786",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ayushman Dash",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102790",
    school: "",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nachiket Ghadei",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102791",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nirnay Bibhudatta",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102792",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Priyanshi Swain",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102793",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rajveer Parida",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102794",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ramesh Dharua",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102795",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "sai priyanshu",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102797",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Tabita Fransis",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102799",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Tanushree Behera",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869102800",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nachiket Ghadei",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102695",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Ruchir Nayak",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102696",
    school: "Sai International School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Angel Fransis Nayak",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102697",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rajveer Parida",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102698",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "sai priyanshu",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102699",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Tanushree",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102701",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rajveer Parida",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102702",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Tabita",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102703",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Nirnay Bibhudatta",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102704",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sagarbala",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869102705",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Ayushman Dash",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102532",
    school: "",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Nachiket Ghadei",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102533",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Priyanshi",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102534",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Angel Fransis Nayak",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102536",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "sai priyanshu",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102538",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Tanushree",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102540",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Rajveer Parida",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102541",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Tabita",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102542",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Nirnay Bibhudatta",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102543",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Adyasha Mahapatra",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869102545",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Angel Fransis Nayak",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102746",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Rajveer Parida",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102747",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "sai priyanshu",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102748",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Tanushree",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102750",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Rajveer Parida",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102751",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Tabita",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102752",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Nirnay Bibhudatta",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102753",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sunil Jain",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869102754",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Bhunia P",
    class: 2,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "MA869102901",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Devansh Rath",
    class: 3,
    score: "35/35",
    orank: 1,
    srank: 1,
    roll: "AM869103106",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Purvesh Kumar Swain",
    class: 3,
    score: "35/35",
    orank: 1,
    srank: 1,
    roll: "AR869103037",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Kamya Sharma",
    class: 3,
    score: "35/35",
    orank: 1,
    srank: 1,
    roll: "AT869103034",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Kumar S",
    class: 3,
    score: "35/35",
    orank: 1,
    srank: 1,
    roll: "SS869103017",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Archisman Patra",
    class: 3,
    score: "33/35",
    orank: 7,
    srank: 1,
    roll: "SS869103210",
    school: "St. Xaviers High School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Ayansh Mishra",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 1,
    roll: "AT869103835",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Snehashish Panda",
    class: 3,
    score: "35/40",
    orank: 23,
    srank: 1,
    roll: "AS869103083",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Prayan Ghosh",
    class: 3,
    score: "25/40",
    orank: 315,
    srank: 1,
    roll: "SC869103538",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "anwesha nanda",
    class: 3,
    score: "25/40",
    orank: 315,
    srank: 1,
    roll: "SC869103540",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Jai krish",
    class: 3,
    score: "25/40",
    orank: 315,
    srank: 1,
    roll: "SC869103547",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Lipsa Acharya",
    class: 3,
    score: "21/35",
    orank: 466,
    srank: 1,
    roll: "AR869103672",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Jai krish",
    class: 3,
    score: "21/35",
    orank: 489,
    srank: 1,
    roll: "AM869103822",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Lipsa Acharya",
    class: 3,
    score: "34/35",
    orank: 2,
    srank: 2,
    roll: "AM869103114",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1500/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "divyapallavi baskey",
    class: 3,
    score: "34/35",
    orank: 2,
    srank: 2,
    roll: "SS869103003",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Adyasha Priyadarsini Mohalik",
    class: 3,
    score: "34/35",
    orank: 3,
    srank: 2,
    roll: "AT869103033",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Shanvi Nayak",
    class: 3,
    score: "34/35",
    orank: 4,
    srank: 2,
    roll: "AR869103035",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Silver Medal",
  },
  {
    name: "divyapallavi baskey",
    class: 3,
    score: "34/40",
    orank: 34,
    srank: 2,
    roll: "AS869103082",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Prayan Ghosh",
    class: 3,
    score: "24/35",
    orank: 98,
    srank: 2,
    roll: "SS869103209",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Lipsa Acharya",
    class: 3,
    score: "22/40",
    orank: 399,
    srank: 2,
    roll: "SC869103541",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Kriyansh Prusty",
    class: 3,
    score: "13/35",
    orank: 465,
    srank: 2,
    roll: "AT869103844",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "anwesha nanda",
    class: 3,
    score: "20/35",
    orank: 498,
    srank: 2,
    roll: "AR869103671",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Nakshit Rout",
    class: 3,
    score: "20/35",
    orank: 511,
    srank: 2,
    roll: "AM869103833",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Saswat Raj",
    class: 3,
    score: "20/35",
    orank: 511,
    srank: 2,
    roll: "AM869103831",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Sai Prasad Patnaik",
    class: 3,
    score: "34/35",
    orank: 2,
    srank: 3,
    roll: "AM869103129",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1500/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Amlan Anil Mohanty",
    class: 3,
    score: "34/35",
    orank: 2,
    srank: 3,
    roll: "SS869103009",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Bibartan Singh",
    class: 3,
    score: "34/35",
    orank: 3,
    srank: 3,
    roll: "AT869103059",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Surya Sidharth Nayak",
    class: 3,
    score: "34/35",
    orank: 4,
    srank: 3,
    roll: "AR869103052",
    school: "Vikash Public school",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Bronze Medal",
  },
  {
    name: "Bivan Prayash Kalas",
    class: 3,
    score: "33/40",
    orank: 49,
    srank: 3,
    roll: "AS869103085",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "aradhya",
    class: 3,
    score: "21/40",
    orank: 414,
    srank: 3,
    roll: "SC869103543",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Sriyarani Panda",
    class: 3,
    score: "19/35",
    orank: 521,
    srank: 3,
    roll: "AR869103677",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Bidisha Saanvi",
    class: 3,
    score: "18/35",
    orank: 654,
    srank: 3,
    roll: "AM869103819",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Priyanshi Sahoo",
    class: 3,
    score: "18/35",
    orank: 654,
    srank: 3,
    roll: "AM869103828",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Surendra kumar Gupta",
    class: 3,
    score: "34/35",
    orank: 2,
    srank: 4,
    roll: "AM869103105",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1500/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Tarun Rajak",
    class: 3,
    score: "34/35",
    orank: 2,
    srank: 4,
    roll: "SS869103016",
    school: "MBS Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Subhashree Swastika Sahu",
    class: 3,
    score: "33/35",
    orank: 7,
    srank: 4,
    roll: "AT869103035",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Swatikrishna Nayak",
    class: 3,
    score: "33/35",
    orank: 11,
    srank: 4,
    roll: "AR869103051",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Amritanshu Das",
    class: 3,
    score: "32/40",
    orank: 56,
    srank: 4,
    roll: "AS869103070",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sriyarani Panda",
    class: 3,
    score: "18/40",
    orank: 542,
    srank: 4,
    roll: "SC869103546",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Suryansh Panda",
    class: 3,
    score: "34/35",
    orank: 2,
    srank: 5,
    roll: "AM869103138",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1500/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Satwik",
    class: 3,
    score: "33/35",
    orank: 7,
    srank: 5,
    roll: "AT869103058",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Isha Pinjani",
    class: 3,
    score: "33/35",
    orank: 7,
    srank: 5,
    roll: "SS869103015",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sreyansh Nayak",
    class: 3,
    score: "33/35",
    orank: 11,
    srank: 5,
    roll: "AR869103067",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sai Stuti",
    class: 3,
    score: "32/40",
    orank: 56,
    srank: 5,
    roll: "AS869103081",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Bighnesh sahoo",
    class: 3,
    score: "17/35",
    orank: 783,
    srank: 5,
    roll: "AM869103821",
    school: "kv 1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Bishal Kumar Bal",
    class: 3,
    score: "33/35",
    orank: 6,
    srank: 6,
    roll: "AM869103128",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Adhwika Kanungo",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 6,
    roll: "AT869103043",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aarav Nayak",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 6,
    roll: "SS869103007",
    school: "RDM School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ashutosh Sahoo",
    class: 3,
    score: "32/35",
    orank: 33,
    srank: 6,
    roll: "AR869103034",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aditya Hruday Mishra",
    class: 3,
    score: "32/40",
    orank: 56,
    srank: 6,
    roll: "AS869103084",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Jagannath Ojha",
    class: 3,
    score: "33/35",
    orank: 6,
    srank: 7,
    roll: "AM869103104",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Sonam Raj",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 7,
    roll: "AT869103057",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Prakrit Shaw",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 7,
    roll: "SS869103010",
    school: "Happy Hours School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Siyanshi Sahoo",
    class: 3,
    score: "32/35",
    orank: 33,
    srank: 7,
    roll: "AR869103036",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ankit Singh",
    class: 3,
    score: "32/40",
    orank: 56,
    srank: 7,
    roll: "AS869103087",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "M.Yogesh",
    class: 3,
    score: "33/35",
    orank: 6,
    srank: 8,
    roll: "AM869103107",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Amritanshu Das",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 8,
    roll: "AT869103060",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ramya Padmanabhan",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 8,
    roll: "SS869103012",
    school: "Narayana Techno School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Jeeshan Panda",
    class: 3,
    score: "32/35",
    orank: 33,
    srank: 8,
    roll: "AR869103050",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Swayam Mohapatra",
    class: 3,
    score: "31/40",
    orank: 78,
    srank: 8,
    roll: "AS869103080",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "KRIYANSH PURTY",
    class: 3,
    score: "15/35",
    orank: 799,
    srank: 8,
    roll: "AM869103823",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Prayan Ghosh",
    class: 3,
    score: "15/35",
    orank: 799,
    srank: 8,
    roll: "AM869103827",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Subhra",
    class: 3,
    score: "33/35",
    orank: 6,
    srank: 9,
    roll: "AM869103137",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Vidyalakshmi N",
    class: 3,
    score: "32/35",
    orank: 15,
    srank: 9,
    roll: "SS869103018",
    school: "St. Xaviers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aditya Narayan Pany",
    class: 3,
    score: "31/35",
    orank: 29,
    srank: 9,
    roll: "AT869103036",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aradhya Moharana",
    class: 3,
    score: "32/35",
    orank: 33,
    srank: 9,
    roll: "AR869103053",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bibartan Singh",
    class: 3,
    score: "30/40",
    orank: 98,
    srank: 9,
    roll: "AS869103069",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Anand Yadav",
    class: 3,
    score: "32/35",
    orank: 19,
    srank: 10,
    roll: "AM869103127",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Alipsha Mohapatra",
    class: 3,
    score: "31/35",
    orank: 23,
    srank: 10,
    roll: "SS869103011",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Kritika Bal",
    class: 3,
    score: "31/35",
    orank: 29,
    srank: 10,
    roll: "AT869103056",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shriyansh Kumar Swain",
    class: 3,
    score: "32/35",
    orank: 33,
    srank: 10,
    roll: "AR869103066",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "S. Omkar Animesh Bal",
    class: 3,
    score: "30/40",
    orank: 98,
    srank: 10,
    roll: "AS869103079",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ANANYA SAHOO",
    class: 3,
    score: "14/35",
    orank: 811,
    srank: 10,
    roll: "AM869103817",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Anwesha Nanda",
    class: 3,
    score: "32/35",
    orank: 19,
    srank: 11,
    roll: "AM869103115",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Piyush Kumar Pati",
    class: 3,
    score: "31/35",
    orank: 29,
    srank: 11,
    roll: "AT869103061",
    school: "Prabhujee English Medium School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bivan Prayash Kalas",
    class: 3,
    score: "28/35",
    orank: 45,
    srank: 11,
    roll: "SS869103006",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ansh Mohapatra",
    class: 3,
    score: "31/35",
    orank: 56,
    srank: 11,
    roll: "AR869103054",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aarav Nayak",
    class: 3,
    score: "30/40",
    orank: 98,
    srank: 11,
    roll: "AS869103086",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Manyata Sethi",
    class: 3,
    score: "13/35",
    orank: 856,
    srank: 11,
    roll: "AM869103825",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Bhagyalami sahoo",
    class: 3,
    score: "32/35",
    orank: 19,
    srank: 12,
    roll: "AM869103108",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ashutosh Sahoo",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 12,
    roll: "AT869103037",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Snehashish Panda",
    class: 3,
    score: "26/35",
    orank: 56,
    srank: 12,
    roll: "SS869103004",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Surya Sidharth Nayak",
    class: 3,
    score: "30/35",
    orank: 89,
    srank: 12,
    roll: "AR869103049",
    school: "Vikash Public school",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aratrika Bhuyan",
    class: 3,
    score: "29/40",
    orank: 145,
    srank: 12,
    roll: "AS869103068",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Saidatta Sahoo",
    class: 3,
    score: "Dec-35",
    orank: 881,
    srank: 12,
    roll: "AM869103830",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sriyarani Panda",
    class: 3,
    score: "Dec-35",
    orank: 881,
    srank: 12,
    roll: "AM869103832",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Bhavyanshi Moulik",
    class: 3,
    score: "32/35",
    orank: 19,
    srank: 13,
    roll: "AM869103136",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tanmaya Sahu",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 13,
    roll: "AT869103055",
    school: "MBS Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sai Stuti",
    class: 3,
    score: "25/35",
    orank: 78,
    srank: 13,
    roll: "SS869103002",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Srutali Raghvi",
    class: 3,
    score: "30/35",
    orank: 89,
    srank: 13,
    roll: "AR869103055",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rihanshi Behera",
    class: 3,
    score: "29/40",
    orank: 145,
    srank: 13,
    roll: "AS869103078",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ankit Yadav",
    class: 3,
    score: "31/35",
    orank: 34,
    srank: 14,
    roll: "AM869103126",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shivam Paital",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 14,
    roll: "AT869103062",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Adhwika Kanungo",
    class: 3,
    score: "29/35",
    orank: 112,
    srank: 14,
    roll: "AR869103042",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aditya Hruday Mishra",
    class: 3,
    score: "23/35",
    orank: 121,
    srank: 14,
    roll: "SS869103005",
    school: "RDM School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Aditya Narayan Pany",
    class: 3,
    score: "29/40",
    orank: 145,
    srank: 14,
    roll: "AS869103094",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Aradhya Mohanty",
    class: 3,
    score: "31/35",
    orank: 34,
    srank: 15,
    roll: "AM869103118",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "S. Omkar Animesh Bal",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 15,
    roll: "AT869103068",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ayushman Mohapatra",
    class: 3,
    score: "29/35",
    orank: 112,
    srank: 15,
    roll: "AR869103048",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Bikram Kumar Sahoo",
    class: 3,
    score: "21/35",
    orank: 145,
    srank: 15,
    roll: "SS869103013",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Namya Swain",
    class: 3,
    score: "28/40",
    orank: 212,
    srank: 15,
    roll: "AS869103067",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Hitusmita Naik",
    class: 3,
    score: "31/35",
    orank: 34,
    srank: 16,
    roll: "AM869103109",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Palak Sai",
    class: 3,
    score: "29/35",
    orank: 50,
    srank: 16,
    roll: "AT869103049",
    school: "Prabhujee English Medium School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aliva Gyanjyoti Singh",
    class: 3,
    score: "29/35",
    orank: 112,
    srank: 16,
    roll: "AR869103056",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Roomani Tomar",
    class: 3,
    score: "21/35",
    orank: 145,
    srank: 16,
    roll: "SS869103014",
    school: "Narayana Techno School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Subhashree Swastika Sahu",
    class: 3,
    score: "28/40",
    orank: 212,
    srank: 16,
    roll: "AS869103092",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Rajdeep Jena",
    class: 3,
    score: "31/35",
    orank: 34,
    srank: 17,
    roll: "AM869103117",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shaan Souransh Sahoo",
    class: 3,
    score: "29/35",
    orank: 50,
    srank: 17,
    roll: "AT869103054",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Vidyalakshmi N",
    class: 3,
    score: "21/35",
    orank: 145,
    srank: 17,
    roll: "SS869103019",
    school: "St. Xaviers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Kausik Ranjan Swain",
    class: 3,
    score: "28/35",
    orank: 156,
    srank: 17,
    roll: "AR869103041",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Suchit Maitra",
    class: 3,
    score: "28/40",
    orank: 212,
    srank: 17,
    roll: "AS869103093",
    school: "Kiit International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Jaikrish Das",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 18,
    roll: "AM869103110",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Rihanshi Behera",
    class: 3,
    score: "29/35",
    orank: 50,
    srank: 18,
    roll: "AT869103067",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Swatikrishna Nayak",
    class: 3,
    score: "28/35",
    orank: 156,
    srank: 18,
    roll: "AR869103047",
    school: "Vikash Public school",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Kamya Sharma",
    class: 3,
    score: "27/40",
    orank: 256,
    srank: 18,
    roll: "AS869103091",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ankit Uthansingh",
    class: 3,
    score: "Dec-35",
    orank: 322,
    srank: 18,
    roll: "SS869103008",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Md. Zisan",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 19,
    roll: "AM869103116",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Kausik Ranjan Swain",
    class: 3,
    score: "28/35",
    orank: 87,
    srank: 19,
    roll: "AT869103042",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Prachilagna Mishra",
    class: 3,
    score: "28/35",
    orank: 156,
    srank: 19,
    roll: "AR869103059",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aditya Mallick",
    class: 3,
    score: "26/40",
    orank: 298,
    srank: 19,
    roll: "AS869103077",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Pratyusha Sahoo",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 20,
    roll: "AM869103135",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sai Suransh Mohanty",
    class: 3,
    score: "28/35",
    orank: 87,
    srank: 20,
    roll: "AT869103053",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Satyam Anand",
    class: 3,
    score: "27/35",
    orank: 234,
    srank: 20,
    roll: "AR869103040",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Adyasha Priyadarsini Mohalik",
    class: 3,
    score: "26/40",
    orank: 298,
    srank: 20,
    roll: "AS869103090",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Reetika Mohapatra",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 21,
    roll: "AM869103119",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Satyam Anand",
    class: 3,
    score: "27/35",
    orank: 100,
    srank: 21,
    roll: "AT869103041",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Namish Das",
    class: 3,
    score: "27/35",
    orank: 234,
    srank: 21,
    roll: "AR869103046",
    school: "Mothers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rishaan Barick",
    class: 3,
    score: "25/40",
    orank: 315,
    srank: 21,
    roll: "AS869103076",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sunyabasi Behera",
    class: 3,
    score: "30/35",
    orank: 45,
    srank: 22,
    roll: "AM869103125",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Aditya Mallick",
    class: 3,
    score: "27/35",
    orank: 100,
    srank: 22,
    roll: "AT869103066",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ayansh Ghosh",
    class: 3,
    score: "26/35",
    orank: 256,
    srank: 22,
    roll: "AR869103065",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Dheeraaj Sethi",
    class: 3,
    score: "25/40",
    orank: 315,
    srank: 22,
    roll: "AS869103089",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ayushman Behera",
    class: 3,
    score: "29/35",
    orank: 59,
    srank: 23,
    roll: "AM869103134",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Shibam Swain",
    class: 3,
    score: "26/35",
    orank: 143,
    srank: 23,
    roll: "AT869103040",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Shezal Suman Sahoo",
    class: 3,
    score: "25/35",
    orank: 301,
    srank: 23,
    roll: "AR869103057",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Bhabana",
    class: 3,
    score: "24/40",
    orank: 345,
    srank: 23,
    roll: "AS869103074",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sourya Ranjan Sahoo",
    class: 3,
    score: "29/35",
    orank: 59,
    srank: 24,
    roll: "AM869103124",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Diljit Patra",
    class: 3,
    score: "26/35",
    orank: 143,
    srank: 24,
    roll: "AT869103048",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Deshna Ojha",
    class: 3,
    score: "24/40",
    orank: 345,
    srank: 24,
    roll: "AS869103075",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Avipsa Panda",
    class: 3,
    score: "24/35",
    orank: 367,
    srank: 24,
    roll: "AR869103043",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Farzeen Fatema",
    class: 3,
    score: "28/35",
    orank: 89,
    srank: 25,
    roll: "AM869103133",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyansh Swain",
    class: 3,
    score: "25/35",
    orank: 176,
    srank: 25,
    roll: "AT869103039",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Prachi Rani Nayak",
    class: 3,
    score: "24/35",
    orank: 367,
    srank: 25,
    roll: "AR869103060",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aarush Nayak",
    class: 3,
    score: "23/40",
    orank: 378,
    srank: 25,
    roll: "AS869103073",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ranjeet kumar panda",
    class: 3,
    score: "28/35",
    orank: 89,
    srank: 26,
    roll: "AM869103101",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Debanshi Parida",
    class: 3,
    score: "25/35",
    orank: 176,
    srank: 26,
    roll: "AT869103047",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Shivam Paital",
    class: 3,
    score: "22/40",
    orank: 399,
    srank: 26,
    roll: "AS869103072",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Tanmaya Sahu",
    class: 3,
    score: "23/35",
    orank: 400,
    srank: 26,
    roll: "AR869103044",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sai prasad Behera",
    class: 3,
    score: "28/35",
    orank: 89,
    srank: 27,
    roll: "AM869103123",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Deshna Ojha",
    class: 3,
    score: "25/35",
    orank: 176,
    srank: 27,
    roll: "AT869103065",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Jeeshan Panda",
    class: 3,
    score: "23/35",
    orank: 400,
    srank: 27,
    roll: "AR869103045",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Piyush Kumar Pati",
    class: 3,
    score: "21/40",
    orank: 414,
    srank: 27,
    roll: "AS869103071",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Dibyansa Mohanty",
    class: 3,
    score: "27/35",
    orank: 134,
    srank: 28,
    roll: "AM869103141",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Lokesh Bhuyan",
    class: 3,
    score: "24/35",
    orank: 199,
    srank: 28,
    roll: "AT869103046",
    school: "St. Xaviers International",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Hitashree Jena",
    class: 3,
    score: "23/35",
    orank: 400,
    srank: 28,
    roll: "AR869103058",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rudransh Pradhan",
    class: 3,
    score: "21/40",
    orank: 414,
    srank: 28,
    roll: "AS869103088",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Priyanka Dakua",
    class: 3,
    score: "27/35",
    orank: 113,
    srank: 29,
    roll: "AM869103122",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Tabita Francis Nayak",
    class: 3,
    score: "24/35",
    orank: 199,
    srank: 29,
    roll: "AT869103052",
    school: "MBS Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Pihu sister",
    class: 3,
    score: "23/35",
    orank: 400,
    srank: 29,
    roll: "AR869103064",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sibanshi Acharya",
    class: 3,
    score: "27/35",
    orank: 134,
    srank: 30,
    roll: "AM869103132",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Bhavana",
    class: 3,
    score: "24/35",
    orank: 199,
    srank: 30,
    roll: "AT869103064",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Rudransh Pradhan",
    class: 3,
    score: "23/35",
    orank: 400,
    srank: 30,
    roll: "AR869103068",
    school: "Narayana Techno School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Purvesh Kumar Swain",
    class: 3,
    score: "23/35",
    orank: 243,
    srank: 31,
    roll: "AT869103038",
    school: "Morning Glory School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Asutosh Behera",
    class: 3,
    score: "26/35",
    orank: 256,
    srank: 31,
    roll: "AM869103121",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Shibam Swain",
    class: 3,
    score: "22/35",
    orank: 437,
    srank: 31,
    roll: "AR869103039",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Ayush Behera",
    class: 3,
    score: "23/35",
    orank: 243,
    srank: 32,
    roll: "AT869103051",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Divyanka Kumari",
    class: 3,
    score: "26/35",
    orank: 256,
    srank: 32,
    roll: "AM869103131",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Hiraniya Das",
    class: 3,
    score: "22/35",
    orank: 437,
    srank: 32,
    roll: "AR869103061",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aarush Nayak",
    class: 3,
    score: "23/35",
    orank: 243,
    srank: 33,
    roll: "AT869103063",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Jigyansa Mohanty",
    class: 3,
    score: "26/35",
    orank: 256,
    srank: 33,
    roll: "AM869103140",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyansh Swain",
    class: 3,
    score: "21/35",
    orank: 466,
    srank: 33,
    roll: "AR869103038",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Maheshi Naik",
    class: 3,
    score: "22/35",
    orank: 265,
    srank: 34,
    roll: "AT869103045",
    school: "St. Xaviers International",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Krishna",
    class: 3,
    score: "25/35",
    orank: 299,
    srank: 34,
    roll: "AM869103120",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Raj Kishore Mallick",
    class: 3,
    score: "20/35+D615",
    orank: 498,
    srank: 34,
    roll: "AR869103062",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aayansh Rout",
    class: 3,
    score: "22/35",
    orank: 265,
    srank: 35,
    roll: "AT869103050",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Dipak Behera",
    class: 3,
    score: "23/35",
    orank: 398,
    srank: 35,
    roll: "AM869103103",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Krishna",
    class: 3,
    score: "20/35",
    orank: 498,
    srank: 35,
    roll: "AR869103063",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Dibyanshi Sahu",
    class: 3,
    score: "21/35",
    orank: 301,
    srank: 36,
    roll: "AT869103044",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Jeet Aditeya Dash",
    class: 3,
    score: "23/35",
    orank: 398,
    srank: 36,
    roll: "AM869103130",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Swayam Mohapatra",
    class: 3,
    score: "21/35",
    orank: 301,
    srank: 37,
    roll: "AT869103069",
    school: "MBS Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Sworup Parida",
    class: 3,
    score: "23/35",
    orank: 398,
    srank: 37,
    roll: "AM869103111",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Kalu chandra Patra",
    class: 3,
    score: "21/35",
    orank: 489,
    srank: 38,
    roll: "AM869103102",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Priyabrata Behera",
    class: 3,
    score: "21/35",
    orank: 489,
    srank: 39,
    roll: "AM869103139",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Subhashree Parida",
    class: 3,
    score: "Dec-35",
    orank: 881,
    srank: 40,
    roll: "AM869103112",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chhanda Madhuri sahu",
    class: 3,
    score: "Nov-35",
    orank: 900,
    srank: "",
    roll: "AM869103113",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Aaradhya Mohanty",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869103889",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "anwesha nanda",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869103818",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Aradhya Maharana",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869103820",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Lipsa Acharya",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869103824",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "P Diksha",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869103826",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Srimaya Sahoo",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869103829",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "KRIYANSH PURTY",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869103670",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "aradhya",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869103673",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "aradhya",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869103674",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "zisan",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869103675",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "P Diksha",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869103676",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Jai krish",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869103678",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Manyata Sethi",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869103679",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Saswat Raj",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869103539",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "aradhya",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869103542",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "zisan",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869103544",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "P Diksha",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869103545",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Manyata Sethi",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869103548",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Nakshit Rout",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869103549",
    school: "",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "anwesha nanda",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103836",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Lipsa Acharya",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103837",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "aradhya",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103838",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "zisan",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103839",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "P Diksha",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103840",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sriyarani Panda",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103841",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Jai krish",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103842",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Manyata Sethi",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869103843",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Md. zisan",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "MA869103833",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "KRIYANSH PURTY",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869103537",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Archisman Patra",
    class: 3,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "",
    school: "St. Xaviers High School",
    exam: "",
    prize: "",
  },
  {
    name: "Apurba Raj Biswal",
    class: 4,
    score: "40/40",
    orank: 1,
    srank: 1,
    roll: "AM869104029",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Manoj Kumar Mishra",
    class: 4,
    score: "40/40",
    orank: 1,
    srank: 1,
    roll: "AR869104012",
    school: "Hightech Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Shrabani Rath",
    class: 4,
    score: "40/40",
    orank: 1,
    srank: 1,
    roll: "AS869104115",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Bibekananda Pradhan",
    class: 4,
    score: "40/40",
    orank: 1,
    srank: 1,
    roll: "AT869104911",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Upendra Nath Ojha",
    class: 4,
    score: "35/40",
    orank: 1,
    srank: 1,
    roll: "SS869104006",
    school: "St. Xaviers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Ishan Swain",
    class: 4,
    score: "35/40",
    orank: 39,
    srank: 1,
    roll: "AR869104771",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Hridoy Barik",
    class: 4,
    score: "30/40",
    orank: 99,
    srank: 1,
    roll: "AT869104871",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "AYUSH KUMAR SAHOO",
    class: 4,
    score: "18/40",
    orank: 461,
    srank: 1,
    roll: "SC869104749",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Sanket Behera",
    class: 4,
    score: "23/40",
    orank: 599,
    srank: 1,
    roll: "AM869104793",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Dr. Pooja Dashmishra",
    class: 4,
    score: "40/40",
    orank: 1,
    srank: 2,
    roll: "AM869104038",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Sudhir Kumar Sahu",
    class: 4,
    score: "40/40",
    orank: 1,
    srank: 2,
    roll: "AR869104024",
    school: "Kiit International School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "DR ANJAN KUMAR SAHOO",
    class: 4,
    score: "35/40",
    orank: 1,
    srank: 2,
    roll: "SS869104026",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Dushmanta kumar Behera",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 2,
    roll: "AS869104103",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Silver Medal",
  },
  {
    name: "Jagan Mohan Patnaik",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 2,
    roll: "AT869104914",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Silver Medal",
  },
  {
    name: "Sanket Behera",
    class: 4,
    score: "30/40",
    orank: 243,
    srank: 2,
    roll: "AR869104770",
    school: "kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Hridoy Barik",
    class: 4,
    score: "22/40",
    orank: 632,
    srank: 2,
    roll: "AM869104787",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Madhumita Panda",
    class: 4,
    score: "40/40",
    orank: 1,
    srank: 3,
    roll: "AM869104072",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "SUSHANTA KUMAR ACHARYA",
    class: 4,
    score: "34/40",
    orank: 3,
    srank: 3,
    roll: "SS869104022",
    school: "Vikash Public school",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Purna Chandra Rout",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 3,
    roll: "AR869104014",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Bronze Medal",
  },
  {
    name: "Urmi Amrita Singh",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 3,
    roll: "AS869104118",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Bronze Medal",
  },
  {
    name: "Suresh Kumar Nayak",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 3,
    roll: "AT869104926",
    school: "Vikash Public school",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Bronze Medal",
  },
  {
    name: "Saikrishna Jena",
    class: 4,
    score: "26/40",
    orank: 432,
    srank: 3,
    roll: "AR869104776",
    school: "Future Bhubaneswar School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "AYUSH KUMAR SAHOO",
    class: 4,
    score: "20/4+D2740",
    orank: 690,
    srank: 3,
    roll: "AM869104784",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Sai Krishna Jena",
    class: 4,
    score: "20/40",
    orank: 690,
    srank: 3,
    roll: "AM869104792",
    school: "Future Bhubaneswar School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "MAMATA BIRUA",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 4,
    roll: "AM869104024",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Subhasmita Patnaik",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 4,
    roll: "AR869104032",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Isha Pinjani",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 4,
    roll: "AS869104124",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Preeti Mahanta",
    class: 4,
    score: "33/40+E1344",
    orank: 7,
    srank: 4,
    roll: "SS869104011",
    school: "Happy Hours School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Uday kumar",
    class: 4,
    score: "38/40",
    orank: 9,
    srank: 4,
    roll: "AT869104915",
    school: "St. Xaviers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Dharashree Naik",
    class: 4,
    score: "21/40",
    orank: 509,
    srank: 4,
    roll: "AR869104774",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Manasranjan Dashmishra",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 5,
    roll: "AM869104037",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Sarada Prasad Patra",
    class: 4,
    score: "39/40+D663",
    orank: 4,
    srank: 5,
    roll: "AR869104040",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Haraprasad Chanda",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 5,
    roll: "AS869104130",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "NARESH KUMAR DAS",
    class: 4,
    score: "32/40",
    orank: 11,
    srank: 5,
    roll: "SS869104007",
    school: "Mothers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Suryakanta Dash",
    class: 4,
    score: "37/40",
    orank: 15,
    srank: 5,
    roll: "AT869104925",
    school: "Vikash Public school",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tikayat Kumar Dhal",
    class: 4,
    score: "20/40",
    orank: 564,
    srank: 5,
    roll: "AR869104028",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Anishka Mohapatra",
    class: 4,
    score: "17/40",
    orank: 786,
    srank: 5,
    roll: "AM869104782",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ritikarani Patra",
    class: 4,
    score: "17/40",
    orank: 786,
    srank: 5,
    roll: "AM869104791",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Trilochan Dhal",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 6,
    roll: "AM869104065",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Jyotiranjan lenka",
    class: 4,
    score: "38/40",
    orank: 9,
    srank: 6,
    roll: "AR869104039",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Manamohan Sahoo",
    class: 4,
    score: "32/40",
    orank: 11,
    srank: 6,
    roll: "SS869104009",
    school: "St. Xaviers International",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Susanta Kumar Nayak",
    class: 4,
    score: "38/40",
    orank: 15,
    srank: 6,
    roll: "AS869104102",
    school: "Vikash Public school",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SABITA PURTY",
    class: 4,
    score: "37/40",
    orank: 15,
    srank: 6,
    roll: "AT869104927",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Chandan Mishra",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 7,
    roll: "AM869104071",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "NABIN KUMAR SAHU",
    class: 4,
    score: "32/40",
    orank: 11,
    srank: 7,
    roll: "SS869104013",
    school: "Mothers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "PRAVAT RANJAN SAHOO",
    class: 4,
    score: "37/40",
    orank: 14,
    srank: 7,
    roll: "AR869104016",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Madhabi Barik",
    class: 4,
    score: "37/40",
    orank: 21,
    srank: 7,
    roll: "AS869104104",
    school: "St. Xaviers International",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Subhasini jena",
    class: 4,
    score: "36/40",
    orank: 23,
    srank: 7,
    roll: "AT869104919",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "G. Divyanshu",
    class: 4,
    score: "15/40",
    orank: 799,
    srank: 7,
    roll: "AM869104788",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Narendra Kumar Dash",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 8,
    roll: "AM869104082",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Abhaya Kumar Mishra",
    class: 4,
    score: "32/40",
    orank: 11,
    srank: 8,
    roll: "SS869104017",
    school: "RDM School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "A SIRISHA",
    class: 4,
    score: "37/40",
    orank: 14,
    srank: 8,
    roll: "AR869104031",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shibani Panda",
    class: 4,
    score: "37/40",
    orank: 21,
    srank: 8,
    roll: "AS869104117",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Goutam Rout",
    class: 4,
    score: "36/40",
    orank: 23,
    srank: 8,
    roll: "AT869104920",
    school: "Kendriya Vidyalaya No 3",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "AYANSH KUMAR BEHERA",
    class: 4,
    score: "14/40",
    orank: 812,
    srank: 8,
    roll: "AM869104783",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Purnima Mohapatra",
    class: 4,
    score: "39/40",
    orank: 4,
    srank: 9,
    roll: "AM869104086",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Subhashis Pattanayak",
    class: 4,
    score: "32/40",
    orank: 11,
    srank: 9,
    roll: "SS869104027",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Pramod Kanhar",
    class: 4,
    score: "37/40",
    orank: 14,
    srank: 9,
    roll: "AR869104036",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Paresh kumar samal",
    class: 4,
    score: "37/40",
    orank: 21,
    srank: 9,
    roll: "AS869104123",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Himanshu Joshi",
    class: 4,
    score: "35/40",
    orank: 35,
    srank: 9,
    roll: "AT869104909",
    school: "Kendriya Vidyalaya No 3",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "RANJITA PANDA",
    class: 4,
    score: "38/40",
    orank: 15,
    srank: 10,
    roll: "AM869104043",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Uttam Kumar Mondal",
    class: 4,
    score: "37/40",
    orank: 21,
    srank: 10,
    roll: "AS869104129",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ananta kumar behera",
    class: 4,
    score: "31/40",
    orank: 23,
    srank: 10,
    roll: "SS869104012",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "AVIMANYU Nayak",
    class: 4,
    score: "36/40",
    orank: 27,
    srank: 10,
    roll: "AR869104009",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "ULLASH KUMAR NAYAK",
    class: 4,
    score: "34/40",
    orank: 35,
    srank: 10,
    roll: "AT869104913",
    school: "St. Xaviers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "KHUSBU JAIN",
    class: 4,
    score: "38/40",
    orank: 15,
    srank: 11,
    roll: "AM869104052",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bidyut Lata Dash",
    class: 4,
    score: "31/40",
    orank: 23,
    srank: 11,
    roll: "SS869104028",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Dambarudhar Majhi",
    class: 4,
    score: "36/40",
    orank: 27,
    srank: 11,
    roll: "AR869104020",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Madhusmita Behura",
    class: 4,
    score: "36/40",
    orank: 34,
    srank: 11,
    roll: "AS869104101",
    school: "St. Xaviers International",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Uday kumar",
    class: 4,
    score: "34/40",
    orank: 35,
    srank: 11,
    roll: "AT869104916",
    school: "St. Xaviers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Komal Krishna",
    class: 4,
    score: "Dec-40",
    orank: 877,
    srank: 11,
    roll: "AM869104789",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Itishree panda",
    class: 4,
    score: "38/40",
    orank: 15,
    srank: 12,
    roll: "AM869104067",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Itishree Panigrahi",
    class: 4,
    score: "34/40",
    orank: 35,
    srank: 12,
    roll: "AT869104928",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Ganesh Mohapatra",
    class: 4,
    score: "35/40",
    orank: 39,
    srank: 12,
    roll: "AR869104030",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "GITANJALI DAS",
    class: 4,
    score: "30/40",
    orank: 45,
    srank: 12,
    roll: "SS869104029",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Subhrajit Patnaik",
    class: 4,
    score: "35/40",
    orank: 56,
    srank: 12,
    roll: "AS869104116",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "LIPSAMAYEE SWAIN",
    class: 4,
    score: "37/40",
    orank: 23,
    srank: 13,
    roll: "AM869104028",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Nibha devi",
    class: 4,
    score: "30/40",
    orank: 45,
    srank: 13,
    roll: "SS869104032",
    school: "Mothers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bibekananda Pradhan",
    class: 4,
    score: "32/40",
    orank: 50,
    srank: 13,
    roll: "AT869104910",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "KISHOR KUMAR JENA",
    class: 4,
    score: "34/40",
    orank: 51,
    srank: 13,
    roll: "AR869104015",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Dolagobinda pradhan",
    class: 4,
    score: "34/40",
    orank: 78,
    srank: 13,
    roll: "AS869104106",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "PRADEEP KUMAR PARIDA",
    class: 4,
    score: "37/40",
    orank: 23,
    srank: 14,
    roll: "AM869104039",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ranjan kumar Ghosh",
    class: 4,
    score: "32/40",
    orank: 50,
    srank: 14,
    roll: "AT869104912",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Jeeshu Pradhan",
    class: 4,
    score: "34/35+D592",
    orank: 51,
    srank: 14,
    roll: "AR869104019",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "DIPNJALI SAHU",
    class: 4,
    score: "29/40",
    orank: 67,
    srank: 14,
    roll: "SS869104031",
    school: "Mothers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Tanushree Ray",
    class: 4,
    score: "34/40",
    orank: 78,
    srank: 14,
    roll: "AS869104110",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "TANUJA MOHANTY",
    class: 4,
    score: "37/40",
    orank: 23,
    srank: 15,
    roll: "AM869104051",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "KHETRA MOHAN BHOI",
    class: 4,
    score: "32/40",
    orank: 50,
    srank: 15,
    roll: "AT869104921",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Pratap Kumar Behera",
    class: 4,
    score: "34/40",
    orank: 51,
    srank: 15,
    roll: "AR869104033",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "MOUSUMI DAS",
    class: 4,
    score: "34/40",
    orank: 78,
    srank: 15,
    roll: "AS869104121",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ch.S.R.Murty",
    class: 4,
    score: "24/40",
    orank: 119,
    srank: 15,
    roll: "SS869104030",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Sayed Shaiban Faizal",
    class: 4,
    score: "37/40",
    orank: 23,
    srank: 16,
    roll: "AM869104060",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Duhkhishyam Prusty",
    class: 4,
    score: "32/40",
    orank: 50,
    srank: 16,
    roll: "AT869104924",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Abhijit Mandal",
    class: 4,
    score: "34/40",
    orank: 51,
    srank: 16,
    roll: "AR869104038",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Santosh Behera",
    class: 4,
    score: "34/40",
    orank: 78,
    srank: 16,
    roll: "AS869104128",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sukanta Kumar Sahu",
    class: 4,
    score: "23/40",
    orank: 145,
    srank: 16,
    roll: "SS869104014",
    school: "Kiit International School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Sayed Shaiban Faizal",
    class: 4,
    score: "37/40",
    orank: 23,
    srank: 17,
    roll: "AM869104061",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Kanta keshab pradhan",
    class: 4,
    score: "34/40",
    orank: 51,
    srank: 17,
    roll: "AR869104050",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Dibya Lochan Bal",
    class: 4,
    score: "31/40",
    orank: 78,
    srank: 17,
    roll: "AT869104922",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "RANJAN KUMAR BEHERA",
    class: 4,
    score: "29/40",
    orank: 131,
    srank: 17,
    roll: "AS869104112",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SADASIBA SAHOO",
    class: 4,
    score: "23/40",
    orank: 145,
    srank: 17,
    roll: "SS869104020",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Prajna Paramita Nanda",
    class: 4,
    score: "37/40",
    orank: 23,
    srank: 18,
    roll: "AM869104068",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bimalendu Dash",
    class: 4,
    score: "34/40",
    orank: 51,
    srank: 18,
    roll: "AR869104052",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Himanshu Joshi",
    class: 4,
    score: "29/40",
    orank: 131,
    srank: 18,
    roll: "AS869104134",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SANGRAM KESHARI BHOI",
    class: 4,
    score: "29/40",
    orank: 152,
    srank: 18,
    roll: "AT869104918",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "SUNIL KUMAR PRADHAN",
    class: 4,
    score: "22/40",
    orank: 167,
    srank: 18,
    roll: "SS869104021",
    school: "Kiit International School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "KALYAN KUMAR SWAIN",
    class: 4,
    score: "36/40",
    orank: 45,
    srank: 19,
    roll: "AM869104026",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "BHABANI PRASAD PARIDA",
    class: 4,
    score: "34/40",
    orank: 51,
    srank: 19,
    roll: "AR869104054",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rotshna Behera",
    class: 4,
    score: "29/40",
    orank: 152,
    srank: 19,
    roll: "AT869104923",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "ANTARYAMI SENAPATI",
    class: 4,
    score: "22/40",
    orank: 167,
    srank: 19,
    roll: "SS869104023",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "DR. MOSARAT AHMAD",
    class: 4,
    score: "28/40",
    orank: 178,
    srank: 19,
    roll: "AS869104126",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "AMITA KUMARI  MAHAPATRO",
    class: 4,
    score: "36/40",
    orank: 45,
    srank: 20,
    roll: "AM869104036",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sasmita das",
    class: 4,
    score: "33/40",
    orank: 98,
    srank: 20,
    roll: "AR869104025",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Manoj Kumar",
    class: 4,
    score: "29/40",
    orank: 152,
    srank: 20,
    roll: "AT869104930",
    school: "Hightech Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Suryakanta Mohanty",
    class: 4,
    score: "21/40",
    orank: 199,
    srank: 20,
    roll: "SS869104008",
    school: "Vikash Public school",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Bhabani Prasad Pujary",
    class: 4,
    score: "27/40",
    orank: 212,
    srank: 20,
    roll: "AS869104122",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sushree Behera",
    class: 4,
    score: "35/40",
    orank: 57,
    srank: 21,
    roll: "AM869104035",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "AKSHAYA KUMAR MUDULI",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 21,
    roll: "AR869104013",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "BISWANATH PATI",
    class: 4,
    score: "21/40",
    orank: 199,
    srank: 21,
    roll: "SS869104016",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "ASHEESH K GUPTA",
    class: 4,
    score: "28/40",
    orank: 223,
    srank: 21,
    roll: "AT869104917",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ashok Kumar Mishra",
    class: 4,
    score: "26/40",
    orank: 245,
    srank: 21,
    roll: "AS869104127",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sasmita Bal",
    class: 4,
    score: "35/40",
    orank: 57,
    srank: 22,
    roll: "AM869104042",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Gayatri Ranbir",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 22,
    roll: "AR869104021",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Pradipta kumar swain",
    class: 4,
    score: "21/40",
    orank: 199,
    srank: 22,
    roll: "SS869104024",
    school: "Prabhujee English Medium School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Manoj Rajput",
    class: 4,
    score: "26/40",
    orank: 245,
    srank: 22,
    roll: "AS869104133",
    school: "Hightech Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Makar Mohan Mallick",
    class: 4,
    score: "23/40",
    orank: 367,
    srank: 22,
    roll: "AT869104929",
    school: "St. Xaviers International",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Manas Ranjan Das",
    class: 4,
    score: "35/40",
    orank: 57,
    srank: 23,
    roll: "AM869104057",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Laxmi Priya Parida",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 23,
    roll: "AR869104026",
    school: "St. Xaviers International",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Mrs Subhasri Mohanty",
    class: 4,
    score: "20/40",
    orank: 231,
    srank: 23,
    roll: "SS869104015",
    school: "Mothers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Jayasmita Nanda",
    class: 4,
    score: "25/40",
    orank: 278,
    srank: 23,
    roll: "AS869104131",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Susant kumar Moharana",
    class: 4,
    score: "35/40",
    orank: 57,
    srank: 24,
    roll: "AM869104062",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Bibhaba Kumar Khuntia",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 24,
    roll: "AR869104029",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Somanath Mahapatra",
    class: 4,
    score: "19/40",
    orank: 267,
    srank: 24,
    roll: "SS869104019",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Monalisa Ray",
    class: 4,
    score: "24/40",
    orank: 298,
    srank: 24,
    roll: "AS869104109",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Bismita Manjari Biswal",
    class: 4,
    score: "35/40",
    orank: 57,
    srank: 25,
    roll: "AM869104063",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Mahrswar Patra",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 25,
    roll: "AR869104035",
    school: "St. Xaviers International",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "PRASANNA KUMAR PATEL",
    class: 4,
    score: "19/40",
    orank: 267,
    srank: 25,
    roll: "SS869104025",
    school: "Happy Hours School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Smruti Ranjan Panda",
    class: 4,
    score: "24/40",
    orank: 289,
    srank: 25,
    roll: "AS869104111",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Pravat Ranjan Behera",
    class: 4,
    score: "35/40",
    orank: 57,
    srank: 26,
    roll: "AM869104074",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Swayamjit Sahoo",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 26,
    roll: "AR869104037",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sanatan Palai",
    class: 4,
    score: "16/40",
    orank: 332,
    srank: 26,
    roll: "SS869104010",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Manasi Praharaj",
    class: 4,
    score: "23/40",
    orank: 345,
    srank: 26,
    roll: "AS869104105",
    school: "Hightech Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Dusmanta Panda",
    class: 4,
    score: "35/40",
    orank: 57,
    srank: 27,
    roll: "AM869104078",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Suresh Chandra Dehury",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 27,
    roll: "AR869104043",
    school: "Vikash Public school",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Bikram Keshari Mohapatra",
    class: 4,
    score: "23/40",
    orank: 345,
    srank: 27,
    roll: "AS869104114",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Lopamudra Das",
    class: 4,
    score: "Dec-40",
    orank: 389,
    srank: 27,
    roll: "SS869104018",
    school: "St. Xaviers International",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Ranjan kumar sethy",
    class: 4,
    score: "34/40",
    orank: 98,
    srank: 28,
    roll: "AM869104041",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "AJAYA KUMAR SAHOO",
    class: 4,
    score: "32/40",
    orank: 123,
    srank: 28,
    roll: "AR869104044",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "BISWA MOHAN MISHRA",
    class: 4,
    score: "23/40",
    orank: 345,
    srank: 28,
    roll: "AS869104119",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Manasi Rout",
    class: 4,
    score: "34/40",
    orank: 98,
    srank: 29,
    roll: "AM869104048",
    school: "Hightech Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chitrasen Rout",
    class: 4,
    score: "31/40",
    orank: 179,
    srank: 29,
    roll: "AR869104010",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Arun kumar",
    class: 4,
    score: "23/40",
    orank: 345,
    srank: 29,
    roll: "AS869104125",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sudhakar Tripathy",
    class: 4,
    score: "34/40",
    orank: 98,
    srank: 30,
    roll: "AM869104066",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "A SIRISHA",
    class: 4,
    score: "31/40",
    orank: 179,
    srank: 30,
    roll: "AR869104022",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Pradeep Kumar Samal",
    class: 4,
    score: "21/40",
    orank: 389,
    srank: 30,
    roll: "AS869104107",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Narendra Kumar Dash",
    class: 4,
    score: "34/40",
    orank: 98,
    srank: 31,
    roll: "AM869104083",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Mother name - Basanti Sethi",
    class: 4,
    score: "31/40",
    orank: 179,
    srank: 31,
    roll: "AR869104034",
    school: "Mothers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Smruti Ranjan Panda",
    class: 4,
    score: "21/40",
    orank: 389,
    srank: 31,
    roll: "AS869104120",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Rashmi Kant Nayak",
    class: 4,
    score: "33/40",
    orank: 125,
    srank: 32,
    roll: "AM869104046",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Jayakrishna Behera",
    class: 4,
    score: "31/40",
    orank: 179,
    srank: 32,
    roll: "AR869104041",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Diptipriya Sethi",
    class: 4,
    score: "19/40",
    orank: 435,
    srank: 32,
    roll: "AS869104113",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "MANAS RANJAN MOHAPATRA",
    class: 4,
    score: "33/40",
    orank: 125,
    srank: 33,
    roll: "AM869104047",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Suryakanta Sahoo",
    class: 4,
    score: "30/40",
    orank: 243,
    srank: 33,
    roll: "AR869104011",
    school: "Vikash Public school",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Susanta Kumar Das",
    class: 4,
    score: "17/40",
    orank: 489,
    srank: 33,
    roll: "AS869104132",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Jeeshu Pradhan",
    class: 4,
    score: "33/40",
    orank: 125,
    srank: 34,
    roll: "AM869104073",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Subrat Routray",
    class: 4,
    score: "30/40",
    orank: 243,
    srank: 34,
    roll: "AR869104023",
    school: "Kiit International School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Subodh Mohapatra",
    class: 4,
    score: "Nov-40",
    orank: 576,
    srank: 34,
    roll: "AS869104108",
    school: "Kiit International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Madhusmita Parida",
    class: 4,
    score: "33/40",
    orank: 125,
    srank: 35,
    roll: "AM869104075",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "AJAY KUMAR PANDA",
    class: 4,
    score: "30/40",
    orank: 243,
    srank: 35,
    roll: "AR869104042",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Atish kumar Biswal",
    class: 4,
    score: "33/40",
    orank: 125,
    srank: 36,
    roll: "AM869104079",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Dhiraj Kumar Pradhan",
    class: 4,
    score: "29/40",
    orank: 299,
    srank: 36,
    roll: "AR869104018",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "PRIYAMBADA SUTAR",
    class: 4,
    score: "33/40",
    orank: 125,
    srank: 37,
    roll: "AM869104089",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "ARUP BARIK",
    class: 4,
    score: "29/40",
    orank: 299,
    srank: 37,
    roll: "AR869104048",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Arati Prabha Mekap",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 38,
    roll: "AM869104027",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Binati Biswal",
    class: 4,
    score: "29/40",
    orank: 299,
    srank: 38,
    roll: "AR869104051",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "RASHMIRANI SWAIN",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 39,
    roll: "AM869104030",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rasmita Behera",
    class: 4,
    score: "28/40",
    orank: 365,
    srank: 39,
    roll: "AR869104046",
    school: "Narayana Techno School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Debasis Mahapatra",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 40,
    roll: "AM869104044",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chitranjan Jagadev",
    class: 4,
    score: "27/40",
    orank: 398,
    srank: 40,
    roll: "AR869104047",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Raja Rakesh Kumar",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 41,
    roll: "AM869104049",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pradeep kumar swain",
    class: 4,
    score: "27/40",
    orank: 398,
    srank: 41,
    roll: "AR869104049",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Anita Rout",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 42,
    roll: "AM869104054",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pradip Kumar Swain",
    class: 4,
    score: "24/40",
    orank: 476,
    srank: 42,
    roll: "AR869104045",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Surjya Narayan Mohanty",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 43,
    roll: "AM869104056",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Dipti Ranjan Sahoo",
    class: 4,
    score: "23/35+D640",
    orank: 489,
    srank: 43,
    roll: "AR869104017",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Manoj Kumar",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 44,
    roll: "AM869104058",
    school: "Hightech Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "R. Satesh rao",
    class: 4,
    score: "21/40",
    orank: 509,
    srank: 44,
    roll: "AR869104027",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Nababrata Behera",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 45,
    roll: "AM869104069",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Mayadhar Mohanty",
    class: 4,
    score: "21/40",
    orank: 509,
    srank: 45,
    roll: "AR869104053",
    school: "Mothers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "KUNTALA KUMARI PANI",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 46,
    roll: "AM869104076",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ritikarani Patra",
    class: 4,
    score: "19/40",
    orank: 589,
    srank: 46,
    roll: "AR869104775",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Himansu Sekhar Singh",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 47,
    roll: "AM869104080",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "GITMALA MOHAPATRA",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 48,
    roll: "AM869104085",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sasmita Sethi",
    class: 4,
    score: "32/40",
    orank: 198,
    srank: 49,
    roll: "AM869104087",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Minarva Maharana",
    class: 4,
    score: "31/40",
    orank: 345,
    srank: 50,
    roll: "AM869104031",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sushri Sangita Sahoo",
    class: 4,
    score: "31/40",
    orank: 345,
    srank: 51,
    roll: "AM869104050",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SANKARSHAN MALLICK",
    class: 4,
    score: "31/40",
    orank: 345,
    srank: 52,
    roll: "AM869104055",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Manoj Kumar",
    class: 4,
    score: "31/40",
    orank: 345,
    srank: 53,
    roll: "AM869104059",
    school: "Hightech Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Pankaj Kumar Mishra",
    class: 4,
    score: "31/40",
    orank: 345,
    srank: 54,
    roll: "AM869104070",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "BANABIHARI PRATIHARI",
    class: 4,
    score: "31/40",
    orank: 345,
    srank: 55,
    roll: "AM869104081",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sourav Kumar Nayak",
    class: 4,
    score: "30/40",
    orank: 421,
    srank: 56,
    roll: "AM869104034",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Manas Routray",
    class: 4,
    score: "30/40",
    orank: 421,
    srank: 57,
    roll: "AM869104053",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Amita Das",
    class: 4,
    score: "30/40",
    orank: 421,
    srank: 58,
    roll: "AM869104084",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Santosh Kumar  Rout",
    class: 4,
    score: "30/40",
    orank: 421,
    srank: 59,
    roll: "AM869104088",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sujata khamari",
    class: 4,
    score: "29/40",
    orank: 478,
    srank: 60,
    roll: "AM869104033",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "MALAY PRADHAN",
    class: 4,
    score: "29/40",
    orank: 478,
    srank: 61,
    roll: "AM869104064",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sangram sahoo",
    class: 4,
    score: "27/40+D222",
    orank: 491,
    srank: 62,
    roll: "AM869104040",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Dipti Prava Baral",
    class: 4,
    score: "24/40",
    orank: 567,
    srank: 63,
    roll: "AM869104032",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sheena A",
    class: 4,
    score: "23/40",
    orank: 599,
    srank: 64,
    roll: "AM869104023",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "CHITTARANJAN PATRO",
    class: 4,
    score: "23/40",
    orank: 599,
    srank: 65,
    roll: "AM869104025",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Prasanta Kumar Mohanty",
    class: 4,
    score: "21/40",
    orank: 689,
    srank: 66,
    roll: "AM869104045",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "GEETANJALI  PARIDA",
    class: 4,
    score: "19/40",
    orank: 721,
    srank: 67,
    roll: "AM869104077",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "DEVRAJ SENAPATI",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869104785",
    school: "",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Dharashree Naik",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869104786",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "RAJESWARI SENAPATI",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869104790",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "TEJUS KUMAR NAYAK",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869104794",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Priyanshu Rout",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869104795",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shrivalli Mishra",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869104772",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "DEVRAJ SENAPATI",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869104773",
    school: "",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Tanvi panda",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869104750",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Anishka Mohapatra",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869104751",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "DEVRAJ SENAPATI",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869104752",
    school: "",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Dhara",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869104753",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ritikarani Patra",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869104754",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Priyanshu Behera",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869104872",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "DEVRAJ SENAPATI",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869104873",
    school: "",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Dhara",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869104874",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ritikarani Patra",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869104875",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "DEVRAJ SENAPATI",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869104343",
    school: "",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Ishan Swain",
    class: 4,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "",
    school: "DAV Public School",
    exam: "",
    prize: "",
  },
  {
    name: "Manoj Kumar Das",
    class: 5,
    score: "40/40",
    orank: 1,
    srank: 1,
    roll: "AM869105040",
    school: "Hightech Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Satyabrata Behera",
    class: 5,
    score: "40/40",
    orank: 1,
    srank: 1,
    roll: "AR869105057",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Ayaana Singh",
    class: 5,
    score: "40/40+E1370",
    orank: 1,
    srank: 1,
    roll: "SS869105014",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Rakesh Kumar Parida",
    class: 5,
    score: "39/40",
    orank: 3,
    srank: 1,
    roll: "AT869105038",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Khetrabasi pradhan",
    class: 5,
    score: "48/50",
    orank: 7,
    srank: 1,
    roll: "AS869105105",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Akshyat Aadit Sahu",
    class: 5,
    score: "34/40",
    orank: 21,
    srank: 1,
    roll: "AM869105895",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Swastik Mohanty",
    class: 5,
    score: "35/40",
    orank: 34,
    srank: 1,
    roll: "AR869105879",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Manish Dev Das",
    class: 5,
    score: "28/40",
    orank: 45,
    srank: 1,
    roll: "SS869105398",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Manish Dev Das",
    class: 5,
    score: "40/50",
    orank: 78,
    srank: 1,
    roll: "SC869105915",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Akshyat Aadit Sahu",
    class: 5,
    score: "40/50",
    orank: 78,
    srank: 1,
    roll: "SC869105917",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Chiranya Choudhury",
    class: 5,
    score: "20/40",
    orank: 400,
    srank: 1,
    roll: "AT869105916",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Rasmita Barik",
    class: 5,
    score: "40/40",
    orank: 1,
    srank: 2,
    roll: "AM869105021",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Urmila Behera",
    class: 5,
    score: "39/40",
    orank: 3,
    srank: 2,
    roll: "AT869105043",
    school: "St. Xaviers Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Haasini G",
    class: 5,
    score: "38/40",
    orank: 3,
    srank: 2,
    roll: "SS869105019",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Sibasis Raymohapatra",
    class: 5,
    score: "38/40",
    orank: 7,
    srank: 2,
    roll: "AR869105070",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Sujit Mallick",
    class: 5,
    score: "46/50",
    orank: 11,
    srank: 2,
    roll: "AS869105089",
    school: "Kiit International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Swastik Mohanty",
    class: 5,
    score: "33/40",
    orank: 32,
    srank: 2,
    roll: "AM869105909",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Chiranya Choudhury",
    class: 5,
    score: "34/40+D713",
    orank: 48,
    srank: 2,
    roll: "AR869105877",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Usha Rani Behera",
    class: 5,
    score: "38/50",
    orank: 126,
    srank: 2,
    roll: "SC869105916",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Maheswar Sahoo",
    class: 5,
    score: "38/40",
    orank: 5,
    srank: 3,
    roll: "AT869105029",
    school: "St. Xaviers International",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Kalyani Debadarsini",
    class: 5,
    score: "37/40",
    orank: 6,
    srank: 3,
    roll: "AM869105034",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "ADITRI ARATRIKA",
    class: 5,
    score: "37/40",
    orank: 6,
    srank: 3,
    roll: "SS869105013",
    school: "RDM School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "KRISHNA SRIVASTAVA",
    class: 5,
    score: "37/40",
    orank: 12,
    srank: 3,
    roll: "AR869105069",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "SHISHIR KUMAR MOHANTA",
    class: 5,
    score: "45/50",
    orank: 23,
    srank: 3,
    roll: "AS869105077",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Anubha Mishra",
    class: 5,
    score: "30/40",
    orank: 49,
    srank: 3,
    roll: "AM869105898",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Shreyanshi Behera",
    class: 5,
    score: "30/40",
    orank: 49,
    srank: 3,
    roll: "AM869105904",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Anubha Mishra",
    class: 5,
    score: "36/50",
    orank: 211,
    srank: 3,
    roll: "SC869105918",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Darshan Jayanadeep",
    class: 5,
    score: "23/40",
    orank: 356,
    srank: 3,
    roll: "AR869105878",
    school: "",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "TAPAN KUMAR PARIDA",
    class: 5,
    score: "37/40",
    orank: 6,
    srank: 4,
    roll: "AM869105029",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tuhin Rajak",
    class: 5,
    score: "37/40",
    orank: 6,
    srank: 4,
    roll: "SS869105016",
    school: "St. Xaviers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "CHITTARANJAN PANDA",
    class: 5,
    score: "37/40",
    orank: 9,
    srank: 4,
    roll: "AT869105048",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "himanshu joshi",
    class: 5,
    score: "37/40",
    orank: 12,
    srank: 4,
    roll: "AR869105073",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SURYA NARAYAN DWIBEDY",
    class: 5,
    score: "44/50",
    orank: 29,
    srank: 4,
    roll: "AS869105090",
    school: "Vikash Public school",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Chiranya Choudhury",
    class: 5,
    score: "31/50",
    orank: 356,
    srank: 4,
    roll: "SC869105919",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sunyabasi",
    class: 5,
    score: "20/40",
    orank: 411,
    srank: 4,
    roll: "AR869105883",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Mita Ray",
    class: 5,
    score: "36/40",
    orank: 9,
    srank: 5,
    roll: "AM869105028",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Raguram Raghulan",
    class: 5,
    score: "34/40",
    orank: 11,
    srank: 5,
    roll: "SS869105012",
    school: "Morning Glory School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "ATIYA TUS SHAKOOR",
    class: 5,
    score: "37/40",
    orank: 12,
    srank: 5,
    roll: "AR869105077",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Basanta Mohanta",
    class: 5,
    score: "35/40",
    orank: 15,
    srank: 5,
    roll: "AT869105027",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sukanta nayak",
    class: 5,
    score: "44/50",
    orank: 29,
    srank: 5,
    roll: "AS869105104",
    school: "Kiit International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Darshan Jayanadeep",
    class: 5,
    score: "26/40",
    orank: 87,
    srank: 5,
    roll: "AM869105900",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyanshi Behera",
    class: 5,
    score: "27/50",
    orank: 421,
    srank: 5,
    roll: "SC869105920",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sanjaya kumar Mansingh",
    class: 5,
    score: "36/40",
    orank: 9,
    srank: 6,
    roll: "AM869105017",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Meghana G",
    class: 5,
    score: "34/40",
    orank: 11,
    srank: 6,
    roll: "SS869105018",
    school: "Mothers Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Manas Ranjan Samal",
    class: 5,
    score: "37/40",
    orank: 12,
    srank: 6,
    roll: "AR869105082",
    school: "St. Xaviers International",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Anita Barik",
    class: 5,
    score: "35/40",
    orank: 15,
    srank: 6,
    roll: "AT869105047",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Samarendra Sahoo",
    class: 5,
    score: "43/50",
    orank: 37,
    srank: 6,
    roll: "AS869105081",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sunyabasi",
    class: 5,
    score: "25/50",
    orank: 450,
    srank: 6,
    roll: "SC869105925",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SUKANTA KUMAR SAHOO",
    class: 5,
    score: "36/40",
    orank: 9,
    srank: 7,
    roll: "AM869105020",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Priyal Pinjani",
    class: 5,
    score: "32/40",
    orank: 21,
    srank: 7,
    roll: "SS869105015",
    school: "Morning Glory School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Rotshna Behera",
    class: 5,
    score: "34/40",
    orank: 34,
    srank: 7,
    roll: "AT869105025",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Madhurima Mohanty",
    class: 5,
    score: "34/40+D687",
    orank: 48,
    srank: 7,
    roll: "AR869105058",
    school: "St. Xaviers International",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Deepak Kumar Nayak",
    class: 5,
    score: "41/50",
    orank: 67,
    srank: 7,
    roll: "AS869105091",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Priyesh Preetidutt",
    class: 5,
    score: "22/40",
    orank: 298,
    srank: 7,
    roll: "AM869105903",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Truptimayee Barik",
    class: 5,
    score: "36/40",
    orank: 9,
    srank: 8,
    roll: "AM869105043",
    school: "St. Xaviers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Itishree Das",
    class: 5,
    score: "34/40",
    orank: 34,
    srank: 8,
    roll: "AT869105042",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "ARABINDA MANDAL",
    class: 5,
    score: "34/40",
    orank: 48,
    srank: 8,
    roll: "AR869105067",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "RANJIT DAS",
    class: 5,
    score: "40/50",
    orank: 78,
    srank: 8,
    roll: "AS869105082",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SURAJ KUMAR SARANGI",
    class: 5,
    score: "23/40",
    orank: 98,
    srank: 8,
    roll: "SS869105011",
    school: "Kiit International School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "BIBHU PRASAD JENA",
    class: 5,
    score: "35/40",
    orank: 15,
    srank: 9,
    roll: "AM869105016",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "himanshu joshi",
    class: 5,
    score: "34/40",
    orank: 48,
    srank: 9,
    roll: "AR869105072",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Pradosh ranjan samal",
    class: 5,
    score: "33/40",
    orank: 57,
    srank: 9,
    roll: "AT869105037",
    school: "Prabhujee English Medium School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Promotosh Bera",
    class: 5,
    score: "40/50",
    orank: 78,
    srank: 9,
    roll: "AS869105092",
    school: "Morning Glory School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Anirudh VK",
    class: 5,
    score: "21/40",
    orank: 111,
    srank: 9,
    roll: "SS869105017",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Dibyajyoti S. Sethy",
    class: 5,
    score: "17/40",
    orank: 543,
    srank: 9,
    roll: "AM869105901",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Manoj Kumar Das",
    class: 5,
    score: "35/40",
    orank: 15,
    srank: 10,
    roll: "AM869105041",
    school: "Hightech Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "ARVIND PAHWA",
    class: 5,
    score: "34/40",
    orank: 48,
    srank: 10,
    roll: "AR869105074",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "MANOJ KUMAR NAYAK",
    class: 5,
    score: "33/40",
    orank: 57,
    srank: 10,
    roll: "AT869105046",
    school: "Hightech Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Krushnachandra Barik",
    class: 5,
    score: "39/50",
    orank: 98,
    srank: 10,
    roll: "AS869105076",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SOURAV KUMAR DAS",
    class: 5,
    score: "16/40+D330",
    orank: 654,
    srank: 10,
    roll: "AM869105906",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Subrat Ranjan Debata",
    class: 5,
    score: "35/40",
    orank: 15,
    srank: 11,
    roll: "AM869105033",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Soumya Ranjan Sahu",
    class: 5,
    score: "34/40",
    orank: 21,
    srank: 11,
    roll: "AM869105022",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Abinash Padhihari",
    class: 5,
    score: "34/40",
    orank: 48,
    srank: 11,
    roll: "AR869105078",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ramesh Kumar panda",
    class: 5,
    score: "32/40",
    orank: 87,
    srank: 11,
    roll: "AT869105030",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "SACHIDANANDA NAYAK",
    class: 5,
    score: "39/50",
    orank: 98,
    srank: 11,
    roll: "AS869105088",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sarojini Pradhan",
    class: 5,
    score: "34/40",
    orank: 21,
    srank: 12,
    roll: "AM869105027",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "VIJAY BAWISKAR",
    class: 5,
    score: "33/40",
    orank: 69,
    srank: 12,
    roll: "AR869105059",
    school: "St. Xaviers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Santosh Sasmal",
    class: 5,
    score: "31/40",
    orank: 121,
    srank: 12,
    roll: "AT869105031",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Kshirod Chandra parida",
    class: 5,
    score: "38/50",
    orank: 126,
    srank: 12,
    roll: "AS869105099",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sunyabasi Behera",
    class: 5,
    score: "13/40",
    orank: 712,
    srank: 12,
    roll: "AM869105907",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SUSANTA KUMAR NAYAK",
    class: 5,
    score: "34/40",
    orank: 21,
    srank: 13,
    roll: "AM869105014",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "ANIL SHETTY",
    class: 5,
    score: "33/40",
    orank: 69,
    srank: 13,
    roll: "AR869105068",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Ranajit Samantaray",
    class: 5,
    score: "31/40",
    orank: 121,
    srank: 13,
    roll: "AT869105034",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "BHAGYASREE SWAIN",
    class: 5,
    score: "37/50",
    orank: 176,
    srank: 13,
    roll: "AS869105080",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SWASTIK KUMAR DAS",
    class: 5,
    score: "Dec-40",
    orank: 765,
    srank: 13,
    roll: "AM869105908",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "JAGANATH BEHERA",
    class: 5,
    score: "33/40",
    orank: 32,
    srank: 14,
    roll: "AM869105035",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Paritosh dey",
    class: 5,
    score: "32/40",
    orank: 88,
    srank: 14,
    roll: "AR869105060",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Premananda sahoo",
    class: 5,
    score: "30/40",
    orank: 145,
    srank: 14,
    roll: "AT869105036",
    school: "Happy Hours School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "SOUMYA RANJAN ROUT",
    class: 5,
    score: "37/50",
    orank: 176,
    srank: 14,
    roll: "AS869105095",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "AJAYA KUMAR SAHOO",
    class: 5,
    score: "32/40",
    orank: 39,
    srank: 15,
    roll: "AM869105023",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tusartanaya Swain",
    class: 5,
    score: "32/40",
    orank: 88,
    srank: 15,
    roll: "AR869105080",
    school: "St. Xaviers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sukanti Barla",
    class: 5,
    score: "30/40",
    orank: 145,
    srank: 15,
    roll: "AT869105041",
    school: "Kiit International School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Dibakar sethi",
    class: 5,
    score: "37/50",
    orank: 176,
    srank: 15,
    roll: "AS869105098",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ASHOK NAYAK",
    class: 5,
    score: "32/40",
    orank: 39,
    srank: 16,
    roll: "AM869105030",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "BISWAJIT PATRA",
    class: 5,
    score: "31/40",
    orank: 121,
    srank: 16,
    roll: "AR869105061",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Khetrabasi pradhan",
    class: 5,
    score: "29/40",
    orank: 187,
    srank: 16,
    roll: "AT869105028",
    school: "Kendriya Vidyalaya No 6",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "SACHIDANANDA NAYAK",
    class: 5,
    score: "36/50",
    orank: 211,
    srank: 16,
    roll: "AS869105087",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Banita swain",
    class: 5,
    score: "32/40",
    orank: 39,
    srank: 17,
    roll: "AM869105015",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Vineeta choudhary",
    class: 5,
    score: "30/40",
    orank: 167,
    srank: 17,
    roll: "AR869105079",
    school: "St. Xaviers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Manindra Behera",
    class: 5,
    score: "29/40",
    orank: 187,
    srank: 17,
    roll: "AT869105044",
    school: "Hightech Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Pratyusha panda",
    class: 5,
    score: "36/50",
    orank: 211,
    srank: 17,
    roll: "AS869105094",
    school: "Happy Hours School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Bauri Bandhu Pradhan",
    class: 5,
    score: "32/40",
    orank: 39,
    srank: 18,
    roll: "AM869105042",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Binod Agasan",
    class: 5,
    score: "29/40",
    orank: 201,
    srank: 18,
    roll: "AR869105062",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Deepak kumar Behera",
    class: 5,
    score: "28/40",
    orank: 212,
    srank: 18,
    roll: "AT869105035",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "SURESH CHANDRA BEHERA",
    class: 5,
    score: "34/50",
    orank: 245,
    srank: 18,
    roll: "AS869105079",
    school: "Kiit International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SASMITA MOHAPATRA",
    class: 5,
    score: "32/40",
    orank: 39,
    srank: 19,
    roll: "AM869105013",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "ARVIND KUMAR SINGH",
    class: 5,
    score: "28/40",
    orank: 234,
    srank: 19,
    roll: "AR869105081",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Khusirani swain",
    class: 5,
    score: "34/50",
    orank: 245,
    srank: 19,
    roll: "AS869105084",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Rotshna Behera",
    class: 5,
    score: "27/40",
    orank: 256,
    srank: 19,
    roll: "AT869105033",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Satyananda Rout",
    class: 5,
    score: "32/40",
    orank: 39,
    srank: 20,
    roll: "AM869105036",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Padmini Biswal",
    class: 5,
    score: "34/50",
    orank: 245,
    srank: 20,
    roll: "AS869105100",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Pradyut Barik",
    class: 5,
    score: "27/40",
    orank: 278,
    srank: 20,
    roll: "AR869105064",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "D RAM",
    class: 5,
    score: "25/40",
    orank: 298,
    srank: 20,
    roll: "AT869105032",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Dukhishyam Swain",
    class: 5,
    score: "31/40",
    orank: 45,
    srank: 21,
    roll: "AM869105031",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sasmita Panigrahi",
    class: 5,
    score: "27/40",
    orank: 278,
    srank: 21,
    roll: "AR869105076",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rajat Kumar Pradhan",
    class: 5,
    score: "33/50",
    orank: 299,
    srank: 21,
    roll: "AS869105101",
    school: "Morning Glory School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Basanta Mohanta",
    class: 5,
    score: "22/40",
    orank: 345,
    srank: 21,
    roll: "AT869105026",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Dipak Kumar Das",
    class: 5,
    score: "30/40",
    orank: 49,
    srank: 22,
    roll: "AM869105032",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Santosh Kumar sahoo",
    class: 5,
    score: "32/50",
    orank: 311,
    srank: 22,
    roll: "AS869105093",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Rathin Chakraborty",
    class: 5,
    score: "25/40",
    orank: 321,
    srank: 22,
    roll: "AR869105056",
    school: "Narayana Techno School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "AMULYA KUMAR DAS",
    class: 5,
    score: "22/40",
    orank: 345,
    srank: 22,
    roll: "AT869105045",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Bijoy Kumar Mohanty",
    class: 5,
    score: "27/40",
    orank: 79,
    srank: 23,
    roll: "AM869105019",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rabindra Parida",
    class: 5,
    score: "23/40",
    orank: 356,
    srank: 23,
    roll: "AR869105063",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Rashmirekha Behera",
    class: 5,
    score: "31/50",
    orank: 356,
    srank: 23,
    roll: "AS869105102",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Archana padhi",
    class: 5,
    score: "21/40",
    orank: 388,
    srank: 23,
    roll: "AT869105039",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Debashish Biswal",
    class: 5,
    score: "27/40",
    orank: 79,
    srank: 24,
    roll: "AM869105039",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rajendra Kumar Sahu",
    class: 5,
    score: "23/40",
    orank: 356,
    srank: 24,
    roll: "AR869105075",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Dilip Kumar Mohanty",
    class: 5,
    score: "30/50",
    orank: 388,
    srank: 24,
    roll: "AS869105103",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ajay kumar jena",
    class: 5,
    score: "20/40",
    orank: 400,
    srank: 24,
    roll: "AT869105040",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Santosh Kumar Behera",
    class: 5,
    score: "24/40+D302",
    orank: 119,
    srank: 25,
    roll: "AM869105026",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "RASHMIRANI SWAIN",
    class: 5,
    score: "22/40",
    orank: 378,
    srank: 25,
    roll: "AR869105066",
    school: "Narayana Techno School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Asit Kumar Basumallik",
    class: 5,
    score: "27/50",
    orank: 421,
    srank: 25,
    roll: "AS869105097",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Bachaknabi Pal",
    class: 5,
    score: "18/40",
    orank: 421,
    srank: 25,
    roll: "AT869105049",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Akshaya Kumar Nayak",
    class: 5,
    score: "23/40",
    orank: 253,
    srank: 26,
    roll: "AM869105012",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Tridib Kar",
    class: 5,
    score: "21/40",
    orank: 398,
    srank: 26,
    roll: "AR869105065",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Jagannath Behera",
    class: 5,
    score: "26/50",
    orank: 444,
    srank: 26,
    roll: "AS869105086",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Anil Kumar Purohit",
    class: 5,
    score: "23/40",
    orank: 253,
    srank: 27,
    roll: "AM869105025",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Manashi Pradhan",
    class: 5,
    score: "21/40",
    orank: 398,
    srank: 27,
    roll: "AR869105071",
    school: "Hightech Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "MONALISA PATI",
    class: 5,
    score: "24/50",
    orank: 456,
    srank: 27,
    roll: "AS869105096",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ashok Kumar Tripathy",
    class: 5,
    score: "23/40",
    orank: 253,
    srank: 28,
    roll: "AM869105038",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "NEWTON BEHERA",
    class: 5,
    score: "23/50",
    orank: 476,
    srank: 28,
    roll: "AS869105078",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Guru Prasad Rajguru",
    class: 5,
    score: "23/40",
    orank: 253,
    srank: 29,
    roll: "AM869105018",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chandrabati Das",
    class: 5,
    score: "23/50",
    orank: 476,
    srank: 29,
    roll: "AS869105083",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Anjan Kumar Nayak",
    class: 5,
    score: "21/40",
    orank: 327,
    srank: 30,
    roll: "AM869105024",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ratikanta Chakra",
    class: 5,
    score: "Dec-50",
    orank: 566,
    srank: 30,
    roll: "AS869105085",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "BISHNU PRASAD SATAPATHY",
    class: 5,
    score: "21/40",
    orank: 327,
    srank: 31,
    roll: "AM869105037",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Soumya ranjan sahoo",
    class: 5,
    score: "14/40",
    orank: 689,
    srank: 32,
    roll: "AM869105905",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ananda Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869105896",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ankit Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869105897",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chiranya Choudhury",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869105899",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Madhusmita Sahoo",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869105902",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Manish Dev Das",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869105910",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Priyesh Preetidutt",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869105876",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Ankit Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869105880",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Ananda Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869105881",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sai krishna",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869105882",
    school: "Future Bhubaneswar School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Ritisha Mohapatra",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869105921",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Madhusmita Sahoo",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869105922",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ankit Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869105923",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ananda Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869105924",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Madhusmita Sahoo",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869105917",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ankit Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869105918",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ananda Yadav",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869105919",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sunyabasi",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869105920",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sai krishna",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869105921",
    school: "Future Bhubaneswar School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Chiranya Choudhury",
    class: 5,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869105399",
    school: "DAV Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "DIYA V S",
    class: 6,
    score: "49/50",
    orank: 1,
    srank: 1,
    roll: "AM869106011",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Kaustav Ranjan Panda",
    class: 6,
    score: "60/60",
    orank: 1,
    srank: 1,
    roll: "AS869106105",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "LAXMIPRIYA BHOI",
    class: 6,
    score: "40/40",
    orank: 1,
    srank: 1,
    roll: "SS869106081",
    school: "St. Xaviers International",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Aaradhya Acharya",
    class: 6,
    score: "49/50",
    orank: 3,
    srank: 1,
    roll: "AT869106111",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Chirantan Satpathy",
    class: 6,
    score: "47/50",
    orank: 4,
    srank: 1,
    roll: "AM869106827",
    school: "lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift worth Rs.750/- + School Gild Medal",
  },
  {
    name: "Tamojyoti Mondal",
    class: 6,
    score: "45/50",
    orank: 9,
    srank: 1,
    roll: "AR869106100",
    school: "MBS Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Chirantan Satpathy",
    class: 6,
    score: "53/60",
    orank: 25,
    srank: 1,
    roll: "SC869106877",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Piyush Tripathy",
    class: 6,
    score: "22/40",
    orank: 78,
    srank: 1,
    roll: "SS869106446",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Aronika Purohit",
    class: 6,
    score: "26/50",
    orank: 234,
    srank: 1,
    roll: "AR869106687",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "MANJLI MAHAPATRA",
    class: 6,
    score: "30/50",
    orank: 246,
    srank: 1,
    roll: "AT869106714",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Nimisha Sholin Ghadai",
    class: 6,
    score: "30/50",
    orank: 246,
    srank: 1,
    roll: "AT869106715",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Jayash Dawik Baral",
    class: 6,
    score: "49/50",
    orank: 1,
    srank: 2,
    roll: "AM869106020",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Pritamsingh Manoj Rajput",
    class: 6,
    score: "45/50",
    orank: 9,
    srank: 2,
    roll: "AR869106104",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "ABHISHEK MISHRA",
    class: 6,
    score: "45/50",
    orank: 11,
    srank: 2,
    roll: "AT869106113",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Jaykrishna jena",
    class: 6,
    score: "36/40",
    orank: 12,
    srank: 2,
    roll: "SS869106079",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Anishka Mohapatra",
    class: 6,
    score: "56/60",
    orank: 15,
    srank: 2,
    roll: "AS869106104",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Aparna Nayak",
    class: 6,
    score: "45/60",
    orank: 78,
    srank: 2,
    roll: "SC869106876",
    school: "",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "MANJLI MAHAPATRA",
    class: 6,
    score: "22/50",
    orank: 289,
    srank: 2,
    roll: "AR869106686",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Smarak Ray",
    class: 6,
    score: "22/50",
    orank: 289,
    srank: 2,
    roll: "AR869106690",
    school: "Kendriya Vidyalaya No4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Piyush Tripathy",
    class: 6,
    score: "35/50",
    orank: 321,
    srank: 2,
    roll: "AM869106832",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Aryan Padhy",
    class: 6,
    score: "47/50",
    orank: 4,
    srank: 3,
    roll: "AM869106050",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Bronze Medal",
  },
  {
    name: "ABILISA NAYAK",
    class: 6,
    score: "45/50",
    orank: 9,
    srank: 3,
    roll: "AR869106110",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Swethanjit Patnaik",
    class: 6,
    score: "56/60",
    orank: 15,
    srank: 3,
    roll: "AS869106106",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Rashika",
    class: 6,
    score: "35/40",
    orank: 19,
    srank: 3,
    roll: "SS869106077",
    school: "Narayana Techno School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Piyush Tripathy",
    class: 6,
    score: "43/60",
    orank: 89,
    srank: 3,
    roll: "SC869106882",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Aarush arun",
    class: 6,
    score: "38/50",
    orank: 143,
    srank: 3,
    roll: "AT869106119",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Bijaypriya Behera",
    class: 6,
    score: "19/50+D741",
    orank: 324,
    srank: 3,
    roll: "AR869106691",
    school: "Narayana School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Chinmay Nandi",
    class: 6,
    score: "31/50",
    orank: 499,
    srank: 3,
    roll: "AM869106826",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "JAJULYAYAN SWAIN",
    class: 6,
    score: "45/50",
    orank: 7,
    srank: 4,
    roll: "AM869106014",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "YEDHANT CHANDA",
    class: 6,
    score: "43/50",
    orank: 15,
    srank: 4,
    roll: "AR869106101",
    school: "St. Xaviers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SATWIK SAMARPAN",
    class: 6,
    score: "54/60",
    orank: 23,
    srank: 4,
    roll: "AS869106102",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Khyati kumari",
    class: 6,
    score: "34/40",
    orank: 25,
    srank: 4,
    roll: "SS869106076",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Palak Sahoo",
    class: 6,
    score: "41/60",
    orank: 101,
    srank: 4,
    roll: "SC869106880",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Anand Pujary",
    class: 6,
    score: "36/50",
    orank: 156,
    srank: 4,
    roll: "AT869106116",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ankita sahoo",
    class: 6,
    score: "29/50+D377",
    orank: 567,
    srank: 4,
    roll: "AM869106821",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SAI SAMPOORNA PATTANAYAK",
    class: 6,
    score: "45/50",
    orank: 7,
    srank: 5,
    roll: "AM869106039",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Mrinali Nanda",
    class: 6,
    score: "41/50",
    orank: 25,
    srank: 5,
    roll: "AR869106102",
    school: "Mothers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Manyasha Ray",
    class: 6,
    score: "50/60",
    orank: 45,
    srank: 5,
    roll: "AS869106100",
    school: "Hightech Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "PRABHJYOT KUMAR BHOI",
    class: 6,
    score: "23/40",
    orank: 56,
    srank: 5,
    roll: "SS869106078",
    school: "Prabhujee English Medium School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "SWAGITKA PRADHAN",
    class: 6,
    score: "39/60",
    orank: 119,
    srank: 5,
    roll: "SC869106879",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Aniket Panda",
    class: 6,
    score: "34/50",
    orank: 178,
    srank: 5,
    roll: "AT869106114",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "SWAGITKA PRADHAN",
    class: 6,
    score: "27/50",
    orank: 699,
    srank: 5,
    roll: "AM869106834",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Abhigyan swain",
    class: 6,
    score: "44/50",
    orank: 11,
    srank: 6,
    roll: "AM869106018",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Prayan Ghosh",
    class: 6,
    score: "41/50",
    orank: 25,
    srank: 6,
    roll: "AR869106109",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Smarak Das",
    class: 6,
    score: "45/60",
    orank: 78,
    srank: 6,
    roll: "AS869106099",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Gourish Kumar Rout",
    class: 6,
    score: "Dec-40",
    orank: 114,
    srank: 6,
    roll: "SS869106080",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Anshuman Mishra",
    class: 6,
    score: "34/50",
    orank: 178,
    srank: 6,
    roll: "AT869106121",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Yasir Arafat",
    class: 6,
    score: "34/60",
    orank: 198,
    srank: 6,
    roll: "SC869106878",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Yasir Arafat",
    class: 6,
    score: "25/50",
    orank: 777,
    srank: 6,
    roll: "AM869106837",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Hridesh Ray",
    class: 6,
    score: "44/50",
    orank: 11,
    srank: 7,
    roll: "AM869106030",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shreeya Sudeshna Pradhan",
    class: 6,
    score: "40/50",
    orank: 43,
    srank: 7,
    roll: "AR869106108",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Saswat Mohapatra",
    class: 6,
    score: "35/60",
    orank: 134,
    srank: 7,
    roll: "AS869106098",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sweta pragyan samal",
    class: 6,
    score: "33/50",
    orank: 199,
    srank: 7,
    roll: "AT869106117",
    school: "MBS Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Banijarani sahoo",
    class: 6,
    score: "32/60",
    orank: 221,
    srank: 7,
    roll: "SC869106881",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Banijarani sahoo",
    class: 6,
    score: "18/50",
    orank: 932,
    srank: 7,
    roll: "AM869106825",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Jeegisha khamari",
    class: 6,
    score: "44/50",
    orank: 11,
    srank: 8,
    roll: "AM869106021",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bismay Pradhan",
    class: 6,
    score: "39/50",
    orank: 56,
    srank: 8,
    roll: "AR869106107",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Baivab Kumar Sethi",
    class: 6,
    score: "34/60",
    orank: 198,
    srank: 8,
    roll: "AS869106103",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "RISAV JENA",
    class: 6,
    score: "31/50",
    orank: 221,
    srank: 8,
    roll: "AT869106115",
    school: "Narayana Techno School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Swethanjit Patnaik",
    class: 6,
    score: "27/60",
    orank: 275,
    srank: 8,
    roll: "SC869106885",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Geetika Mahapatra",
    class: 6,
    score: "43/50+D355",
    orank: 27,
    srank: 9,
    roll: "AM869106032",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "B.K.SAI SUMAN",
    class: 6,
    score: "37/50",
    orank: 78,
    srank: 9,
    roll: "AR869106099",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aarav Pinjani",
    class: 6,
    score: "28/50",
    orank: 275,
    srank: 9,
    roll: "AT869106118",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Ansuman Panda",
    class: 6,
    score: "23/60+D929",
    orank: 345,
    srank: 9,
    roll: "AS869106101",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "PRATISHRUTI MOHAPATRA",
    class: 6,
    score: "43/50",
    orank: 27,
    srank: 10,
    roll: "AM869106035",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sarthak Joshi",
    class: 6,
    score: "37/50",
    orank: 78,
    srank: 10,
    roll: "AR869106106",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "BELAL KHURSHEED AHMAD",
    class: 6,
    score: "28/50",
    orank: 275,
    srank: 10,
    roll: "AT869106120",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Priyanshu Behera",
    class: 6,
    score: "43/50",
    orank: 27,
    srank: 11,
    roll: "AM869106049",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Harshita kumari",
    class: 6,
    score: "37/50",
    orank: 78,
    srank: 11,
    roll: "AR869106112",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Akshaj Ranjan",
    class: 6,
    score: "23/50",
    orank: 311,
    srank: 11,
    roll: "AT869106112",
    school: "Kendriya Vidyalaya No 5",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Shreejit Rout",
    class: 6,
    score: "43/50",
    orank: 27,
    srank: 12,
    roll: "AM869106042",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Priyadeep Das",
    class: 6,
    score: "34/50",
    orank: 99,
    srank: 12,
    roll: "AR869106103",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "SWAYAMBHU  RATHA",
    class: 6,
    score: "43/50",
    orank: 27,
    srank: 13,
    roll: "AM869106024",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shreyash Joshi",
    class: 6,
    score: "32/50",
    orank: 141,
    srank: 13,
    roll: "AR869106105",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSH PANDA",
    class: 6,
    score: "42/50",
    orank: 49,
    srank: 14,
    roll: "AM869106031",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Namrata Patnaik",
    class: 6,
    score: "32/50",
    orank: 141,
    srank: 14,
    roll: "AR869106111",
    school: "Mothers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Mohit Mohan Pradhan",
    class: 6,
    score: "42/50",
    orank: 49,
    srank: 15,
    roll: "AM869106019",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shaswat Dashmishra",
    class: 6,
    score: "42/50",
    orank: 49,
    srank: 16,
    roll: "AM869106025",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Anshuman Nayak",
    class: 6,
    score: "41/50",
    orank: 67,
    srank: 17,
    roll: "AM869106022",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Prathama suryasnata",
    class: 6,
    score: "41/50",
    orank: 67,
    srank: 18,
    roll: "AM869106051",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ayushman Dibyasingh Behera",
    class: 6,
    score: "40/50",
    orank: 87,
    srank: 19,
    roll: "AM869106048",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "PADMAJA PRAJNA PARIDA",
    class: 6,
    score: "40/50",
    orank: 87,
    srank: 20,
    roll: "AM869106027",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Partha Sarathi Samal",
    class: 6,
    score: "40/50",
    orank: 87,
    srank: 21,
    roll: "AM869106052",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shubham Krishna",
    class: 6,
    score: "40/50",
    orank: 87,
    srank: 22,
    roll: "AM869106023",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Aryaveer Raj Biswal",
    class: 6,
    score: "39/50",
    orank: 112,
    srank: 23,
    roll: "AM869106017",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Samarjeet Rout",
    class: 6,
    score: "39/50",
    orank: 112,
    srank: 24,
    roll: "AM869106036",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ashirbad Nayak",
    class: 6,
    score: "38/50",
    orank: 189,
    srank: 25,
    roll: "AM869106047",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Krisha Mohanty",
    class: 6,
    score: "38/50",
    orank: 189,
    srank: 26,
    roll: "AM869106037",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Paramita sethy",
    class: 6,
    score: "37/50",
    orank: 235,
    srank: 27,
    roll: "AM869106029",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "DIVYANSHI DRISHYA",
    class: 6,
    score: "36/50",
    orank: 287,
    srank: 28,
    roll: "AM869106016",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Baibhav Varchasva Routray",
    class: 6,
    score: "34/50",
    orank: 376,
    srank: 29,
    roll: "AM869106041",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "OMKAR DEBADUTTA BEHURA",
    class: 6,
    score: "34/50",
    orank: 376,
    srank: 30,
    roll: "AM869106046",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Samaresh sahoo",
    class: 6,
    score: "34/50",
    orank: 376,
    srank: 31,
    roll: "AM869106028",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SUCHISHREE PATRO",
    class: 6,
    score: "34/50",
    orank: 376,
    srank: 32,
    roll: "AM869106013",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Saurish Dashmishra",
    class: 6,
    score: "28/50",
    orank: 665,
    srank: 33,
    roll: "AM869106026",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sudikshya Mohanty",
    class: 6,
    score: "27/50",
    orank: 699,
    srank: 34,
    roll: "AM869106033",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Arnesh Nayak",
    class: 6,
    score: "26/50",
    orank: 723,
    srank: 35,
    roll: "AM869106034",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sanskar Das",
    class: 6,
    score: "25/50",
    orank: 777,
    srank: 36,
    roll: "AM869106045",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shriya Sahoo",
    class: 6,
    score: "24/50",
    orank: 799,
    srank: 37,
    roll: "AM869106038",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Anush Sasmit Das",
    class: 6,
    score: "23/50",
    orank: 832,
    srank: 38,
    roll: "AM869106015",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "RUTURAJ BIRUA",
    class: 6,
    score: "23/50",
    orank: 832,
    srank: 39,
    roll: "AM869106012",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "TANMAY JAIN",
    class: 6,
    score: "23/50",
    orank: 832,
    srank: 40,
    roll: "AM869106040",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Tamanna Mohanty",
    class: 6,
    score: "22/50",
    orank: 888,
    srank: 41,
    roll: "AM869106044",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSH MALLICK",
    class: 6,
    score: "21/50",
    orank: 930,
    srank: 42,
    roll: "AM869106043",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "ABHIJNAN MOHANTY",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106820",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Aronika Purohit",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106822",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Bijaypriya Behera",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106838",
    school: "Narayana School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chitranjali Priyadarshini",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106828",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Manjali Mahapatra",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106839",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nimisha Sholin Ghadai",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106829",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "P k zyatika",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106830",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Palak Sahoo",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106831",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sairaj Ray Samantasinghar",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106833",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Smarak Ray",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106823",
    school: "Kendriya Vidyalaya No4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Smita Mohanty",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106840",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Subham Ray",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106824",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Swastik behera",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106835",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Swethanjit Patnaik",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869106836",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chitranjali Priyadarshini",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869106684",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "ABHIJNAN MOHANTY",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869106685",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Nimisha Sholin Ghadai",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869106688",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sairaj Ray Samantasinghar",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869106689",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Smarak Ray",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AS869102999",
    school: "Kendriya Vidyalaya No4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ABHIJNAN MOHANTY",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869106875",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Nimisha Sholin Ghadai",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869106883",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sairaj Ray Samantasinghar",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869106884",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Chitranjali Priyadarshini",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869106712",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "ABHIJNAN MOHANTY",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869106713",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sairaj Ray Samantasinghar",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869106716",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Smarak Ray",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869106717",
    school: "kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Subham",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869106718",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Smarak Ray",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "ss869102987",
    school: "Kendriya Vidyalaya No4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "ABHIJNAN MOHANTY",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869106445",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Subham",
    class: 6,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "",
    school: "Lumbini Public School",
    exam: "",
    prize: "",
  },
  {
    name: "Mansa Dash",
    class: 7,
    score: "50/50",
    orank: 1,
    srank: 1,
    roll: "AM869107048",
    school: "Hightech Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Arush behera",
    class: 7,
    score: "50/50",
    orank: 1,
    srank: 1,
    roll: "AR869107080",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "SMURTI SAGARIKA",
    class: 7,
    score: "39/40",
    orank: 1,
    srank: 1,
    roll: "SS869107016",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "SWAGATIKA ACHARYA",
    class: 7,
    score: "49/50",
    orank: 3,
    srank: 1,
    roll: "AT869107044",
    school: "Vikash Public school",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Partha Sarathi Dhal",
    class: 7,
    score: "58/60",
    orank: 4,
    srank: 1,
    roll: "AS869107126",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "Avilipsa Swain",
    class: 7,
    score: "38/50",
    orank: 45,
    srank: 1,
    roll: "AT869107575",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Avilipsa Swain",
    class: 7,
    score: "42/50",
    orank: 47,
    srank: 1,
    roll: "AM869107733",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Avilipsa Swain",
    class: 7,
    score: "54/60",
    orank: 61,
    srank: 1,
    roll: "SC869107957",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Samrata Sahoo",
    class: 7,
    score: "27/40+E1395",
    orank: 89,
    srank: 1,
    roll: "SS869107547",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Laksh Agarwal",
    class: 7,
    score: "31/50",
    orank: 187,
    srank: 1,
    roll: "AR869107594",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Monalisha Sahoo",
    class: 7,
    score: "31/50",
    orank: 187,
    srank: 1,
    roll: "AR869107595",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Chitranjali Priyadarshini",
    class: 7,
    score: "58/60",
    orank: 4,
    srank: 2,
    roll: "AS869107131",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Gift Worth Rs.750/- + School Silver Medal",
  },
  {
    name: "Alavya Rout",
    class: 7,
    score: "48/50",
    orank: 5,
    srank: 2,
    roll: "AM869107059",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "AKANKSHYA MOHAPATRA",
    class: 7,
    score: "37/40",
    orank: 7,
    srank: 2,
    roll: "SS869107011",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Subhashree Swain",
    class: 7,
    score: "48/50",
    orank: 8,
    srank: 2,
    roll: "AT869107046",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Jagrutee Bisoi",
    class: 7,
    score: "47/50",
    orank: 9,
    srank: 2,
    roll: "AR869107086",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Monalisha Sahoo",
    class: 7,
    score: "35/50",
    orank: 134,
    srank: 2,
    roll: "AT869107570",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Samikshya Swain",
    class: 7,
    score: "34/50",
    orank: 245,
    srank: 2,
    roll: "AM869107738",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Samrata Sahoo",
    class: 7,
    score: "26/50",
    orank: 265,
    srank: 2,
    roll: "AR869107593",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Laksh Agarwal",
    class: 7,
    score: "44/60",
    orank: 273,
    srank: 2,
    roll: "SC869107958",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Ipsita Priyadarsini",
    class: 7,
    score: "48/50",
    orank: 5,
    srank: 3,
    roll: "AM869107093",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Ch.Thrushita",
    class: 7,
    score: "34/40",
    orank: 11,
    srank: 3,
    roll: "SS869107012",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "AMRIT BARIK",
    class: 7,
    score: "57/60",
    orank: 12,
    srank: 3,
    roll: "AS869107146",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Depankar Mahanta",
    class: 7,
    score: "46/50",
    orank: 15,
    srank: 3,
    roll: "AR869107079",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Purnanshu Chaudhuri",
    class: 7,
    score: "45/50",
    orank: 15,
    srank: 3,
    roll: "AT869107050",
    school: "Morning Glory School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Chitranjali Priyadarshini",
    class: 7,
    score: "32/50",
    orank: 211,
    srank: 3,
    roll: "AT869107574",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Ankita priyadarshini Barik",
    class: 7,
    score: "42/60",
    orank: 312,
    srank: 3,
    roll: "SC869107963",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Akankhya Bisoyi",
    class: 7,
    score: "31/50",
    orank: 432,
    srank: 3,
    roll: "AM869107732",
    school: "kv 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Anshuman Mishra",
    class: 7,
    score: "47/50",
    orank: 9,
    srank: 4,
    roll: "AM869107089",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Anup Kumar Rajak",
    class: 7,
    score: "34/40",
    orank: 11,
    srank: 4,
    roll: "SS869107014",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "AARAB PRADHAN",
    class: 7,
    score: "40/50",
    orank: 21,
    srank: 4,
    roll: "AT869107043",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "K Anchita",
    class: 7,
    score: "56/60",
    orank: 23,
    srank: 4,
    roll: "AS869107120",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "OMKARNATH PATI",
    class: 7,
    score: "45/50",
    orank: 28,
    srank: 4,
    roll: "AR869107084",
    school: "Prabhujee English Medium School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Laksh Agarwal",
    class: 7,
    score: "30/50",
    orank: 222,
    srank: 4,
    roll: "AT869107569",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Monalisha Sahoo",
    class: 7,
    score: "39/60",
    orank: 378,
    srank: 4,
    roll: "SC869107959",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sushrishalini sahoo",
    class: 7,
    score: "39/60",
    orank: 378,
    srank: 4,
    roll: "SC869107967",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Laksh Agarwal",
    class: 7,
    score: "30/50",
    orank: 491,
    srank: 4,
    roll: "AM869107736",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Monalisha Sahoo",
    class: 7,
    score: "30/50",
    orank: 491,
    srank: 4,
    roll: "AM869107737",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyansh Dhal",
    class: 7,
    score: "47/50",
    orank: 9,
    srank: 5,
    roll: "AM869107061",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "DEBA PRASAD NAYAK",
    class: 7,
    score: "34/40",
    orank: 11,
    srank: 5,
    roll: "SS869107017",
    school: "ODM Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SHREYA SAMARPITA",
    class: 7,
    score: "40/50",
    orank: 21,
    srank: 5,
    roll: "AT869107049",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Jagadeesh Mohanty",
    class: 7,
    score: "43/50",
    orank: 35,
    srank: 5,
    roll: "AR869107076",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Swagatika Pradhan",
    class: 7,
    score: "55/60",
    orank: 43,
    srank: 5,
    roll: "AS869107117",
    school: "Vikash Public school",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sayed Izaan Iftekhar",
    class: 7,
    score: "46/50",
    orank: 14,
    srank: 6,
    roll: "AM869107057",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Priyanshi Swain",
    class: 7,
    score: "33/40",
    orank: 21,
    srank: 6,
    roll: "SS869107018",
    school: "Morning Glory School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Shubhranshi Palai",
    class: 7,
    score: "43/50",
    orank: 35,
    srank: 6,
    roll: "AR869107078",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "R. Trishika",
    class: 7,
    score: "55/60",
    orank: 43,
    srank: 6,
    roll: "AS869107125",
    school: "Morning Glory School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "ADARSH KUMAR SAHOO",
    class: 7,
    score: "37/50",
    orank: 89,
    srank: 6,
    roll: "AT869107048",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sibani Pattnaik",
    class: 7,
    score: "26/50",
    orank: 587,
    srank: 6,
    roll: "AM869107743",
    school: "Venketeswar English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shivnarayan Panda",
    class: 7,
    score: "46/50",
    orank: 14,
    srank: 7,
    roll: "AM869107074",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SHAKTI SAIFALYA SAHU",
    class: 7,
    score: "43/50",
    orank: 35,
    srank: 7,
    roll: "AR869107081",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Adyasha sethi",
    class: 7,
    score: "55/60",
    orank: 43,
    srank: 7,
    roll: "AS869107132",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SAI JEEV DEVAANSH JENA",
    class: 7,
    score: "32/40",
    orank: 43,
    srank: 7,
    roll: "SS869107019",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "AYUSHMAN SENAPATI",
    class: 7,
    score: "34/50",
    orank: 176,
    srank: 7,
    roll: "AT869107045",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Akankhya Bisoyi",
    class: 7,
    score: "25/50",
    orank: 610,
    srank: 7,
    roll: "AM869107731",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chitranjali Priyadarshini",
    class: 7,
    score: "25/50+D462",
    orank: 610,
    srank: 7,
    roll: "AM869107745",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "KRIYANSH PURTY",
    class: 7,
    score: "45/50",
    orank: 21,
    srank: 8,
    roll: "AM869107050",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Saswat Jagadev",
    class: 7,
    score: "55/60",
    orank: 43,
    srank: 8,
    roll: "AS869107145",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Udit Narayan Sahoo",
    class: 7,
    score: "39/50",
    orank: 67,
    srank: 8,
    roll: "AR869107088",
    school: "St. Xaviers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aparna Nayak",
    class: 7,
    score: "26/40",
    orank: 111,
    srank: 8,
    roll: "SS869107015",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "PRAGNYA PARAMITA PATEL",
    class: 7,
    score: "33/50",
    orank: 199,
    srank: 8,
    roll: "AT869107047",
    school: "Happy Hours School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sarthak Ranjan Jally",
    class: 7,
    score: "45/50",
    orank: 21,
    srank: 9,
    roll: "AM869107046",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SAANVI PARIDA",
    class: 7,
    score: "55/60",
    orank: 43,
    srank: 9,
    roll: "AS869107152",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sudhansu Mohan Sahoo",
    class: 7,
    score: "34/50",
    orank: 98,
    srank: 9,
    roll: "AR869107077",
    school: "Kiit International School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "AADYA SUDIPTA SUBUDHI",
    class: 7,
    score: "23/40",
    orank: 156,
    srank: 9,
    roll: "SS869107013",
    school: "RDM School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Sibani Pattnaik",
    class: 7,
    score: "15/50",
    orank: 768,
    srank: 9,
    roll: "AM869107742",
    school: "Venketeswar English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SUBHAM KUMAR PARIDA",
    class: 7,
    score: "45/50",
    orank: 21,
    srank: 10,
    roll: "AM869107073",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sai shree ram khuntia",
    class: 7,
    score: "53/60",
    orank: 78,
    srank: 10,
    roll: "AS869107127",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Snehashree Mahapatra",
    class: 7,
    score: "34/50",
    orank: 98,
    srank: 10,
    roll: "AR869107087",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Shivansh kumar",
    class: 7,
    score: "44/50",
    orank: 29,
    srank: 11,
    roll: "AM869107055",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Sail saurojit lenka",
    class: 7,
    score: "53/60",
    orank: 78,
    srank: 11,
    roll: "AS869107137",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Arpita Mishra",
    class: 7,
    score: "32/50",
    orank: 141,
    srank: 11,
    roll: "AR869107085",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Shradha Rout",
    class: 7,
    score: "44/50",
    orank: 29,
    srank: 12,
    roll: "AM869107091",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Nikhilesh Mohanty",
    class: 7,
    score: "53/60",
    orank: 78,
    srank: 12,
    roll: "AS869107151",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Archisman Mohanty",
    class: 7,
    score: "26/50",
    orank: 265,
    srank: 12,
    roll: "AR869107083",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "SHREEYANSHU PANDA",
    class: 7,
    score: "44/50",
    orank: 29,
    srank: 13,
    roll: "AM869107072",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ashutosh Mohapatra",
    class: 7,
    score: "51/60",
    orank: 98,
    srank: 13,
    roll: "AS869107128",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Hiranmaya Sahu",
    class: 7,
    score: "23/50",
    orank: 345,
    srank: 13,
    roll: "AR869107082",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "ARADHYA KRITI PRADHAN",
    class: 7,
    score: "43/50",
    orank: 35,
    srank: 14,
    roll: "AM869107060",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Soumya Mohanty",
    class: 7,
    score: "51/60",
    orank: 98,
    srank: 14,
    roll: "AS869107130",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ARCHITA PRIYADARSHINI",
    class: 7,
    score: "43/50",
    orank: 35,
    srank: 15,
    roll: "AM869107090",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Saishree Soumya Patra",
    class: 7,
    score: "51/60",
    orank: 98,
    srank: 15,
    roll: "AS869107138",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Smruti Smaranika Pradhan",
    class: 7,
    score: "43/50",
    orank: 35,
    srank: 16,
    roll: "AM869107069",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Mridul Krishna Dash",
    class: 7,
    score: "51/60",
    orank: 98,
    srank: 16,
    roll: "AS869107150",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sayed Izaan Iftekhar",
    class: 7,
    score: "42/50+D408",
    orank: 47,
    srank: 17,
    roll: "AM869107056",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Ushasi Priyadarshini Ojha",
    class: 7,
    score: "51/60",
    orank: 98,
    srank: 17,
    roll: "AS869107153",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Swagatam Parida",
    class: 7,
    score: "42/50",
    orank: 47,
    srank: 18,
    roll: "AM869107071",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "K Sahasra",
    class: 7,
    score: "50/60",
    orank: 111,
    srank: 18,
    roll: "AS869107129",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Swayam Sudip Sahoo",
    class: 7,
    score: "42/50",
    orank: 47,
    srank: 19,
    roll: "AM869107094",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Pratyush Kumar Behera",
    class: 7,
    score: "50/60",
    orank: 111,
    srank: 19,
    roll: "AS869107139",
    school: "Happy Hours School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Biswajit Pradhan",
    class: 7,
    score: "41/50",
    orank: 58,
    srank: 20,
    roll: "AM869107095",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Satya Sworup Pratihari",
    class: 7,
    score: "48/60",
    orank: 145,
    srank: 20,
    roll: "AS869107123",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Subhransu Sekhar Moharana",
    class: 7,
    score: "41/50",
    orank: 58,
    srank: 21,
    roll: "AM869107058",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Abhipsha Mandal",
    class: 7,
    score: "48/60",
    orank: 145,
    srank: 21,
    roll: "AS869107136",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ABHIJIT  JENA",
    class: 7,
    score: "40/50",
    orank: 79,
    srank: 22,
    roll: "AM869107092",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "AJASWINI PANDA",
    class: 7,
    score: "48/60",
    orank: 145,
    srank: 22,
    roll: "AS869107140",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shivansh kumar",
    class: 7,
    score: "40/50",
    orank: 79,
    srank: 23,
    roll: "AM869107054",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sibani Biswal",
    class: 7,
    score: "48/60",
    orank: 145,
    srank: 23,
    roll: "AS869107149",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Avishi Panda",
    class: 7,
    score: "39/50",
    orank: 98,
    srank: 24,
    roll: "AM869107068",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "ANANYA SAHOO",
    class: 7,
    score: "47/60",
    orank: 189,
    srank: 24,
    roll: "AS869107142",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shreehon Nanda",
    class: 7,
    score: "39/50",
    orank: 98,
    srank: 25,
    roll: "AM869107064",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SAMARTH SWARUP DAS",
    class: 7,
    score: "46/60",
    orank: 231,
    srank: 25,
    roll: "AS869107154",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Aaeshanee Mishra",
    class: 7,
    score: "37/50",
    orank: 134,
    srank: 26,
    roll: "AM869107067",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sharanya samal",
    class: 7,
    score: "45/60",
    orank: 256,
    srank: 26,
    roll: "AS869107119",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Devanshi Manaswi krishna",
    class: 7,
    score: "37/50",
    orank: 134,
    srank: 27,
    roll: "AM869107087",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Adyaveer Sahu",
    class: 7,
    score: "45/60",
    orank: 256,
    srank: 27,
    roll: "AS869107122",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shivansh kumar",
    class: 7,
    score: "37/50",
    orank: 134,
    srank: 28,
    roll: "AM869107053",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Jaswitha pradhan",
    class: 7,
    score: "45/60",
    orank: 256,
    srank: 28,
    roll: "AS869107148",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Aditi Dash",
    class: 7,
    score: "36/50",
    orank: 176,
    srank: 29,
    roll: "AM869107078",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Abhilipsa Dalai",
    class: 7,
    score: "43/60",
    orank: 299,
    srank: 29,
    roll: "AS869107124",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Amrita Biswal",
    class: 7,
    score: "35/50",
    orank: 199,
    srank: 30,
    roll: "AM869107075",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "ROHAN PATRA",
    class: 7,
    score: "43/60",
    orank: 299,
    srank: 30,
    roll: "AS869107133",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Biswaranjan Mishra",
    class: 7,
    score: "35/50",
    orank: 199,
    srank: 31,
    roll: "AM869107063",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ananya swain",
    class: 7,
    score: "43/60",
    orank: 299,
    srank: 31,
    roll: "AS869107147",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Saiprasad Nayak",
    class: 7,
    score: "35/50",
    orank: 199,
    srank: 32,
    roll: "AM869107086",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Swagat Kumar Dehury",
    class: 7,
    score: "41/60",
    orank: 345,
    srank: 32,
    roll: "AS869107141",
    school: "Vikash Public school",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shree Aradhya Bal",
    class: 7,
    score: "35/50",
    orank: 199,
    srank: 33,
    roll: "AM869107045",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Akankhya Bisoyi",
    class: 7,
    score: "40/60",
    orank: 359,
    srank: 33,
    roll: "SC869107966",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ayush Kumar Sahoo",
    class: 7,
    score: "34/50",
    orank: 245,
    srank: 34,
    roll: "AM869107088",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Abhishikta Sahoo",
    class: 7,
    score: "39/60",
    orank: 378,
    srank: 34,
    roll: "AS869107135",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sai krishna Pati",
    class: 7,
    score: "34/50",
    orank: 245,
    srank: 35,
    roll: "AM869107051",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Monalisa Majhi",
    class: 7,
    score: "34/60",
    orank: 411,
    srank: 35,
    roll: "AS869107118",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sanskruti Mohanty Mohapatra",
    class: 7,
    score: "34/50",
    orank: 245,
    srank: 36,
    roll: "AM869107082",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Labdhi Swain",
    class: 7,
    score: "34/60",
    orank: 411,
    srank: 36,
    roll: "AS869107143",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SHREYANSH PRUSTY",
    class: 7,
    score: "34/50",
    orank: 245,
    srank: 37,
    roll: "AM869107047",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "P.k zyatika",
    class: 7,
    score: "33/60+D952",
    orank: 456,
    srank: 37,
    roll: "AS869107134",
    school: "Prabhujee English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Yashita Sanvi Mishra",
    class: 7,
    score: "33/50",
    orank: 301,
    srank: 38,
    roll: "AM869107066",
    school: "St. Xaviers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ruturaja Routray",
    class: 7,
    score: "32/60",
    orank: 489,
    srank: 38,
    roll: "AS869107121",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Bikash Kumar Mallick",
    class: 7,
    score: "32/50",
    orank: 365,
    srank: 39,
    roll: "AM869107052",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Aradhya Ayushnita",
    class: 7,
    score: "27/60",
    orank: 543,
    srank: 39,
    roll: "AS869107144",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Jyotiprakash Behera",
    class: 7,
    score: "32/50",
    orank: 356,
    srank: 40,
    roll: "AM869107070",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Anantika Dash",
    class: 7,
    score: "32/50",
    orank: 356,
    srank: 41,
    roll: "AM869107079",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Naibedya Mohanty",
    class: 7,
    score: "31/50",
    orank: 432,
    srank: 42,
    roll: "AM869107080",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Adityanshu Behera",
    class: 7,
    score: "30/50",
    orank: 491,
    srank: 43,
    roll: "AM869107065",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "PRATYUSH RANJAN",
    class: 7,
    score: "30/50+D432",
    orank: 491,
    srank: 44,
    roll: "AM869107081",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SHREYA SARGAM",
    class: 7,
    score: "25/50",
    orank: 610,
    srank: 45,
    roll: "AM869107077",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nityashree Navya",
    class: 7,
    score: "23/50",
    orank: 675,
    srank: 46,
    roll: "AM869107083",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyansi Nayak",
    class: 7,
    score: "23/50",
    orank: 675,
    srank: 47,
    roll: "AM869107049",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sreyanshi Tripathy",
    class: 7,
    score: "23/50",
    orank: 675,
    srank: 48,
    roll: "AM869107062",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Tejash Singh",
    class: 7,
    score: "23/50",
    orank: 675,
    srank: 49,
    roll: "AM869107076",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "HARSHITA PRIYADARSHINI",
    class: 7,
    score: "22/50",
    orank: 699,
    srank: 50,
    roll: "AM869107085",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Šübhąm Priyadarshi  Rout",
    class: 7,
    score: "21/50",
    orank: 709,
    srank: 51,
    roll: "AM869107084",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHMAN BEHERA",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869107734",
    school: "AMO,ASO,ATHO,ARAO,ASSO",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Chitranjan Sahoo",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869107744",
    school: "Lumbini Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Harshita Kumari",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869107735",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Samrata Sahoo",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869107739",
    school: "kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SAMRATA SAHOO",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869107740",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyanshi Sahu",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869107741",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sambit Sagar Majhi",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869107592",
    school: "lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyanshi Sahu",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869107596",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHMAN BEHERA",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869107597",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Harshita Kumari",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869107598",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sambit Sagar Majhi",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869107599",
    school: "Lumbini Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "SAMRATA SAHOO",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869107600",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Chitranjali Priyadarshini",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869107601",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "SUSHRISHALINI SAHOO",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107954",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sibani Pattnaik",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107955",
    school: "Venketeswar English Medium School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Akankhya Bisoyi",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107956",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyanshi Sahu",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107960",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHMAN BEHERA",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107961",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Harshita Kumari",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107962",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Satya sworup pratihari",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107964",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyash Pattanaik",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869107965",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyanshi Sahu",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869107571",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHMAN BEHERA",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869107572",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Harshita Kumari",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869107573",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyanshi Sahu",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869107548",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHMAN BEHERA",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869107549",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Harshita Kumari",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869107550",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Akankhya Bisoyi",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869107551",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "SAMRATA SAHOO",
    class: 7,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869107552",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Punyatoya Mansingh",
    class: 8,
    score: "50/50",
    orank: 1,
    srank: 1,
    roll: "AM869108066",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Shreyasi Madhumita",
    class: 8,
    score: "49/50",
    orank: 4,
    srank: 1,
    roll: "AT869108034",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Gift worth Rs.750/-",
  },
  {
    name: "ARNAV DWIBEDY",
    class: 8,
    score: "58/60",
    orank: 5,
    srank: 1,
    roll: "AS869108060",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Priyansu pritam Jena",
    class: 8,
    score: "45/50",
    orank: 34,
    srank: 1,
    roll: "AR869108056",
    school: "Morning Glory School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Sai Kiran",
    class: 8,
    score: "39/50",
    orank: 87,
    srank: 1,
    roll: "AT869108515",
    school: "Sai International School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Shubham Baisag",
    class: 8,
    score: "30/50",
    orank: 121,
    srank: 1,
    roll: "SS869108453",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Shubham Baisag",
    class: 8,
    score: "40/60",
    orank: 298,
    srank: 1,
    roll: "SC869108798",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Ipsita Priyadarsini",
    class: 8,
    score: "33/50",
    orank: 498,
    srank: 1,
    roll: "AM869108656",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Tejash Kumar Nayak",
    class: 8,
    score: "49/50",
    orank: 2,
    srank: 2,
    roll: "AM869108073",
    school: "MBS Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "SRUTI PRAKASH ROUT",
    class: 8,
    score: "57/60",
    orank: 9,
    srank: 2,
    roll: "AS869108065",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Sushree Santoshini Panda",
    class: 8,
    score: "45/50",
    orank: 25,
    srank: 2,
    roll: "AT869108035",
    school: "Vikash Public school",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Manish Dev Das",
    class: 8,
    score: "44/50",
    orank: 45,
    srank: 2,
    roll: "AR869108058",
    school: "Hightech Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Dibyasha Samal",
    class: 8,
    score: "22/50+E968",
    orank: 232,
    srank: 2,
    roll: "SS869108455",
    school: "Sai International School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Ipsita Priyadarsini",
    class: 8,
    score: "30/60",
    orank: 555,
    srank: 2,
    roll: "SC869108799",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Amrutanshu Satapathy",
    class: 8,
    score: "31/50",
    orank: 598,
    srank: 2,
    roll: "AM869108651",
    school: "kv 1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Nirbikar Rout",
    class: 8,
    score: "31/50",
    orank: 598,
    srank: 2,
    roll: "AM869108658",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Dibyajyoti Pradhan",
    class: 8,
    score: "49/50",
    orank: 2,
    srank: 3,
    roll: "AM869108091",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Kshama sagar Nayak",
    class: 8,
    score: "57/60",
    orank: 9,
    srank: 3,
    roll: "AS869108074",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Deepanswu Behera",
    class: 8,
    score: "45/50",
    orank: 25,
    srank: 3,
    roll: "AT869108040",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Usha Rani Behera",
    class: 8,
    score: "42/50",
    orank: 111,
    srank: 3,
    roll: "AR869108059",
    school: "St. Xaviers Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Shubham Baisag",
    class: 8,
    score: "28/50",
    orank: 675,
    srank: 3,
    roll: "AM869108659",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "NIHAR RANJAN MOHANTA",
    class: 8,
    score: "49/50",
    orank: 2,
    srank: 4,
    roll: "AM869108094",
    school: "Mothers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Abhijit Sahoo",
    class: 8,
    score: "44/50",
    orank: 41,
    srank: 4,
    roll: "AT869108041",
    school: "RDM School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Saswat Chakra",
    class: 8,
    score: "55/60",
    orank: 45,
    srank: 4,
    roll: "AS869108055",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Roshni Parida",
    class: 8,
    score: "37/50",
    orank: 267,
    srank: 4,
    roll: "AR869108054",
    school: "Narayana Techno School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Deepsikha routray",
    class: 8,
    score: "49/50",
    orank: 2,
    srank: 5,
    roll: "AM869108101",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.1000/- (prize shared)Olympiad Silver Medal",
  },
  {
    name: "Sejal shreya panda",
    class: 8,
    score: "55/60",
    orank: 45,
    srank: 5,
    roll: "AS869108064",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tanush Sasmal",
    class: 8,
    score: "39/50",
    orank: 87,
    srank: 5,
    roll: "AT869108036",
    school: "MBS Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "ELISKA HARO",
    class: 8,
    score: "34/50",
    orank: 321,
    srank: 5,
    roll: "AR869108057",
    school: "ODM Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Aronika Purohit",
    class: 8,
    score: "48/50",
    orank: 7,
    srank: 6,
    roll: "AM869108074",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Vansika Aronkita",
    class: 8,
    score: "55/60",
    orank: 45,
    srank: 6,
    roll: "AS869108075",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "D Anwesha",
    class: 8,
    score: "38/50",
    orank: 111,
    srank: 6,
    roll: "AT869108037",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Pratyusha pritisnigdha",
    class: 8,
    score: "34/50",
    orank: 321,
    srank: 6,
    roll: "AR869108060",
    school: "Happy Hours School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Chinmay Nandi",
    class: 8,
    score: "48/50",
    orank: 7,
    srank: 7,
    roll: "AM869108100",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "SAI PARTHASARATHI NAYAK",
    class: 8,
    score: "54/60",
    orank: 67,
    srank: 7,
    roll: "AS869108058",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Manaswinee samal",
    class: 8,
    score: "35/50",
    orank: 145,
    srank: 7,
    roll: "AT869108042",
    school: "Hightech Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "AARADHYA DAS",
    class: 8,
    score: "33/50",
    orank: 389,
    srank: 7,
    roll: "AR869108061",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Subhankar Das",
    class: 8,
    score: "47/50",
    orank: 13,
    srank: 8,
    roll: "AM869108081",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SUBRACHI PATI",
    class: 8,
    score: "53/60",
    orank: 89,
    srank: 8,
    roll: "AS869108066",
    school: "Kiit International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sarthak Ranjan Jally",
    class: 8,
    score: "32/50",
    orank: 198,
    srank: 8,
    roll: "AT869108038",
    school: "Kendriya Vidyalaya No 4",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Aarwika padhi",
    class: 8,
    score: "23/50+D766",
    orank: 432,
    srank: 8,
    roll: "AR869108055",
    school: "RDM School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Krupa Barik",
    class: 8,
    score: "47/50",
    orank: 13,
    srank: 9,
    roll: "AM869108093",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Jyotirmaya Mohanty",
    class: 8,
    score: "52/60",
    orank: 111,
    srank: 9,
    roll: "AS869108073",
    school: "Kendriya Vidyalaya No 6",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Ashutosh Samantaray",
    class: 8,
    score: "23/50",
    orank: 265,
    srank: 9,
    roll: "AT869108039",
    school: "ODM Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Subrat Kumar Mohanty",
    class: 8,
    score: "46/50",
    orank: 29,
    srank: 10,
    roll: "AM869108068",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Swastik Behera",
    class: 8,
    score: "51/60+D990",
    orank: 123,
    srank: 10,
    roll: "AS869108072",
    school: "Vikash Public school",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "PRIYANSHU DAS",
    class: 8,
    score: "46/50",
    orank: 29,
    srank: 11,
    roll: "AM869108099",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Srishti Basumallik",
    class: 8,
    score: "50/60",
    orank: 143,
    srank: 11,
    roll: "AS869108067",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Aditya Prasad Rajguru",
    class: 8,
    score: "45/50",
    orank: 34,
    srank: 12,
    roll: "AM869108067",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize",
  },
  {
    name: "Abha Sritami Pradhan",
    class: 8,
    score: "49/60",
    orank: 167,
    srank: 12,
    roll: "AS869108071",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "SAKET KUMAR DAS",
    class: 8,
    score: "45/50",
    orank: 34,
    srank: 13,
    roll: "AM869108076",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Gayatri Mohanta",
    class: 8,
    score: "49/60",
    orank: 167,
    srank: 13,
    roll: "AS869108078",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "PRUTHIRAJ PARIDA",
    class: 8,
    score: "45/50",
    orank: 34,
    srank: 14,
    roll: "AM869108078",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SWATI SELLINA SAHOO",
    class: 8,
    score: "48/60",
    orank: 213,
    srank: 14,
    roll: "AS869108070",
    school: "Vikash Public school",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "RUDRA BEHERA",
    class: 8,
    score: "44/50",
    orank: 41,
    srank: 15,
    roll: "AM869108095",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Maitri Mohanta",
    class: 8,
    score: "48/60",
    orank: 213,
    srank: 15,
    roll: "AS869108077",
    school: "St. Xaviers International",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "AYANSH KUMAR BEHERA",
    class: 8,
    score: "43/50",
    orank: 49,
    srank: 16,
    roll: "AM869108096",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "SAI PARTHASARATHI NAYAK",
    class: 8,
    score: "45/60",
    orank: 256,
    srank: 16,
    roll: "AS869108057",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ADYA ADWITA SWAIN",
    class: 8,
    score: "42/50",
    orank: 61,
    srank: 17,
    roll: "AM869108097",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sandeep Sakshyam sahoo",
    class: 8,
    score: "45/60",
    orank: 256,
    srank: 17,
    roll: "AS869108063",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Samikshya Sahoo",
    class: 8,
    score: "41/50",
    orank: 79,
    srank: 18,
    roll: "AM869108098",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Priyansi parida",
    class: 8,
    score: "38/60",
    orank: 332,
    srank: 18,
    roll: "AS869108069",
    school: "Morning Glory School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "AMRUTANSHU SATAPATHY",
    class: 8,
    score: "40/50",
    orank: 99,
    srank: 19,
    roll: "AM869108086",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Prakriti Priyadarshini",
    class: 8,
    score: "34/60",
    orank: 388,
    srank: 19,
    roll: "AS869108056",
    school: "Happy Hours School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Abhipsha Debata",
    class: 8,
    score: "38/50",
    orank: 256,
    srank: 20,
    roll: "AM869108082",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sreyansh Raj Mallick",
    class: 8,
    score: "34/60",
    orank: 388,
    srank: 20,
    roll: "AS869108059",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ASMIN KUMAR SAHOO",
    class: 8,
    score: "37/50",
    orank: 299,
    srank: 21,
    roll: "AM869108072",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Adrija Bera",
    class: 8,
    score: "34/60",
    orank: 388,
    srank: 21,
    roll: "AS869108062",
    school: "RDM School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sushree Sankshipta Swain",
    class: 8,
    score: "37/50",
    orank: 299,
    srank: 22,
    roll: "AM869108080",
    school: "Vikash Public school",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nirav Ranjan Jally",
    class: 8,
    score: "34/60",
    orank: 388,
    srank: 22,
    roll: "AS869108076",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Bijaylaxmi Rout",
    class: 8,
    score: "36/50",
    orank: 365,
    srank: 23,
    roll: "AM869108085",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "ms.Saikrishna priyadarsini",
    class: 8,
    score: "33/60",
    orank: 456,
    srank: 23,
    roll: "AS869108068",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Priyanshu Tripathy",
    class: 8,
    score: "36/50",
    orank: 365,
    srank: 24,
    roll: "AM869108087",
    school: "Morning Glory School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ritwik ronak pradhan",
    class: 8,
    score: "31/60",
    orank: 511,
    srank: 24,
    roll: "AS869108079",
    school: "Narayana Techno School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Saideep Sahu",
    class: 8,
    score: "35/50",
    orank: 389,
    srank: 25,
    roll: "AM869108071",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Mousumi Nayak",
    class: 8,
    score: "23/60",
    orank: 600,
    srank: 25,
    roll: "AS869108061",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "AARUSH KUMAR SAHOO",
    class: 8,
    score: "34/50",
    orank: 456,
    srank: 26,
    roll: "AM869108069",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "ARNI ARADHYA",
    class: 8,
    score: "34/50",
    orank: 456,
    srank: 27,
    roll: "AM869108079",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "MAHESH KUMAR BEHERA",
    class: 8,
    score: "33/50",
    orank: 498,
    srank: 28,
    roll: "AM869108084",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Rudrabhishek Biswal",
    class: 8,
    score: "32/50",
    orank: 555,
    srank: 29,
    roll: "AM869108088",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Divyanshi Divyajyoti",
    class: 8,
    score: "30/50",
    orank: 632,
    srank: 30,
    roll: "AM869108083",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Biren Das",
    class: 8,
    score: "27/50",
    orank: 699,
    srank: 31,
    roll: "AM869108089",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ayushman Barik",
    class: 8,
    score: "23/50",
    orank: 701,
    srank: 32,
    roll: "AM869108070",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "SANKET BEHERA",
    class: 8,
    score: "23/50",
    orank: 701,
    srank: 33,
    roll: "AM869108075",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Aum Krishna Baral",
    class: 8,
    score: "23/50",
    orank: 701,
    srank: 34,
    roll: "AM869108077",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Amitesh Barik",
    class: 8,
    score: "23/50",
    orank: 701,
    srank: 35,
    roll: "AM869108092",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sudipta Das",
    class: 8,
    score: "19/50+D486",
    orank: 888,
    srank: 36,
    roll: "AM869108090",
    school: "Kiit International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Anshika Swain",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869108652",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Ayush Ray",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869108653",
    school: "dAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Bhabesh Kumar Panigrahi",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869108654",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Dibyasha Samal",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869108655",
    school: "Sai International School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Nikhil Kumar Das",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869108657",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Anshika Swain",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869108401",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Dibyasha Samal",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869108402",
    school: "Sai International School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Anshika Swain",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869108800",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Dibyasha Samal",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869108801",
    school: "Sai International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Nikhil Kumar Das",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869108802",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Nirbikar Rout",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869108803",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Kiran",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869108804",
    school: "Sai International School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Akhil jyoti Nayak",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869108805",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Anshika Swain",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869108512",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Dibyasha Samal",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869108513",
    school: "Sai International School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Nikhil Kumar Das",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869108514",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Nikhil Kumar Das",
    class: 8,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869108454",
    school: "DAV Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "MANJIT NAYAK",
    class: 9,
    score: "49/50",
    orank: 1,
    srank: 1,
    roll: "AM869109012",
    school: "Hightech Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Sai Sweta Pradhan",
    class: 9,
    score: "57/60",
    orank: 5,
    srank: 1,
    roll: "AS869109082",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Subhashree swain",
    class: 9,
    score: "40/50",
    orank: 45,
    srank: 1,
    roll: "AT869109316",
    school: "Lumbini Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "DEBIPRASAD DASH",
    class: 9,
    score: "35/60",
    orank: 99,
    srank: 1,
    roll: "SC869109406",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Sritish Sahoo",
    class: 9,
    score: "33/50",
    orank: 132,
    srank: 1,
    roll: "AM869109463",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Omm Swaroop Rout",
    class: 9,
    score: "36/50",
    orank: 231,
    srank: 1,
    roll: "AR869109441",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "School Gold Medal",
  },
  {
    name: "Ruhan Mohanty",
    class: 9,
    score: "49/50",
    orank: 1,
    srank: 2,
    roll: "AM869109018",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "ISHA MANDAL",
    class: 9,
    score: "56/60",
    orank: 11,
    srank: 2,
    roll: "AS869109078",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "Trishanu Kar",
    class: 9,
    score: "49/50",
    orank: 1,
    srank: 3,
    roll: "AM869109025",
    school: "St. Xaviers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "ARCHIT KRISHNA SRIVASTAVA",
    class: 9,
    score: "55/60",
    orank: 16,
    srank: 3,
    roll: "AS869109080",
    school: "ODM Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Hitanshi Behera",
    class: 9,
    score: "47/50+D514",
    orank: 11,
    srank: 4,
    roll: "AM869109017",
    school: "Kendriya Vidyalaya No 3",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "shreyash joshi",
    class: 9,
    score: "47/60",
    orank: 56,
    srank: 4,
    roll: "AS869109084",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Pabitra Barik",
    class: 9,
    score: "47/50",
    orank: 11,
    srank: 5,
    roll: "AM869109024",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Tamanna Ray",
    class: 9,
    score: "46/60",
    orank: 69,
    srank: 5,
    roll: "AS869109081",
    school: "MBS Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "PRADYUMNA PANDA",
    class: 9,
    score: "44/50",
    orank: 15,
    srank: 6,
    roll: "AM869109014",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "OMISHA SHETTY",
    class: 9,
    score: "34/60",
    orank: 113,
    srank: 6,
    roll: "AS869109079",
    school: "Mothers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Spandan Chakraborty",
    class: 9,
    score: "43/50",
    orank: 21,
    srank: 7,
    roll: "AM869109016",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "shreyash joshi",
    class: 9,
    score: "34/60",
    orank: 113,
    srank: 7,
    roll: "AS869109083",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Pallabi dey",
    class: 9,
    score: "43/50",
    orank: 21,
    srank: 8,
    roll: "AM869109020",
    school: "Prabhujee English Medium School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "AREEVA SWAIN",
    class: 9,
    score: "43/50",
    orank: 21,
    srank: 9,
    roll: "AM869109026",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Adwit Dutta",
    class: 9,
    score: "41/50",
    orank: 45,
    srank: 10,
    roll: "AM869109015",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "AUM BAWISKAR",
    class: 9,
    score: "41/50",
    orank: 45,
    srank: 11,
    roll: "AM869109019",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Reehana Parinika",
    class: 9,
    score: "36/50",
    orank: 76,
    srank: 12,
    roll: "AM869109023",
    school: "Narayana Techno School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Saranya Agasan",
    class: 9,
    score: "35/50",
    orank: 87,
    srank: 13,
    roll: "AM869109022",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sai Santosh Barik",
    class: 9,
    score: "34/50",
    orank: 99,
    srank: 14,
    roll: "AM869109013",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "BHAVESH PATRA",
    class: 9,
    score: "23/50",
    orank: 675,
    srank: 15,
    roll: "AM869109021",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHI SENAPATI",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869109460",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "DEBIPRASAD DASH",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869109461",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shrihan Mohanty",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869109462",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Sundaram Ray samantasinghar",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869109464",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Omm Swaroop Rout",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AM869109465",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Shrihan Mohanty",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869109440",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHI SENAPATI",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869109442",
    school: "DAV Public School",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Sundaram Ray samantasinghar",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AR869109443",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Reasoning & Aptitude Olympiad (ARAO) 2023-24",
    prize: "",
  },
  {
    name: "Shrihan Mohanty",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869109407",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHI SENAPATI",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869109408",
    school: "DAV Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Pranita dash",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869109409",
    school: "Lumbini Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Sundaram Ray samantasinghar",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SC869109410",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Jyotirmaya Mohanty",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869109317",
    school: "",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Shrihan Mohanty",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869109318",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHI SENAPATI",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869109319",
    school: "DAV Public School",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "Sundaram Ray samantasinghar",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "AT869109320",
    school: "Kendriya Vidyalaya No1",
    exam: "AsianTalent Hunt Olympiad (ATHO) 2023-24",
    prize: "",
  },
  {
    name: "AYUSHI SENAPATI",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869109211",
    school: "DAV Public School",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "Sundaram Ray samantasinghar",
    class: 9,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "SS869109212",
    school: "Kendriya Vidyalaya No1",
    exam: "Asian Space Science Olympiad (ASSO) 2023-24",
    prize: "",
  },
  {
    name: "PRANET PAHWA",
    class: 10,
    score: "50/50",
    orank: 1,
    srank: 1,
    roll: "AM869110099",
    school: "Happy Hours School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Cash Award of Rs.2000/- (prize shared)Olympiad Gold Medal",
  },
  {
    name: "Sai Smarak",
    class: 10,
    score: "55/60",
    orank: 15,
    srank: 1,
    roll: "AS869110089",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "special Prize + School Gold Medal",
  },
  {
    name: "Chiranjib Bagh",
    class: 10,
    score: "47/50",
    orank: 5,
    srank: 2,
    roll: "AM869110105",
    school: "ODM Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize + School silver Medal",
  },
  {
    name: "ASUTOSH SARANGI",
    class: 10,
    score: "50/60",
    orank: 56,
    srank: 2,
    roll: "AS869110088",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Silver Medal",
  },
  {
    name: "Lasya Priya sahu",
    class: 10,
    score: "45/50",
    orank: 7,
    srank: 3,
    roll: "AM869110100",
    school: "St. Xaviers International",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "Special Prize + school bronze Medal",
  },
  {
    name: "Aditya Samal",
    class: 10,
    score: "45/60",
    orank: 79,
    srank: 3,
    roll: "AS869110087",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "School Bronze Medal",
  },
  {
    name: "Anjaneya Padhihari",
    class: 10,
    score: "45/50",
    orank: 7,
    srank: 4,
    roll: "AM869110103",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Anurag Mishra",
    class: 10,
    score: "45/60",
    orank: 79,
    srank: 4,
    roll: "AS869110092",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "ABHINAV SINGH",
    class: 10,
    score: "44/50",
    orank: 15,
    srank: 5,
    roll: "AM869110106",
    school: "RDM School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Bijayapriya Behera",
    class: 10,
    score: "43/60",
    orank: 100,
    srank: 5,
    roll: "AS869110090",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "YASIR AHMAD",
    class: 10,
    score: "38/50",
    orank: 34,
    srank: 6,
    roll: "AM869110102",
    school: "St. Xaviers Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "special Prize",
  },
  {
    name: "Priyabrata Sahoo",
    class: 10,
    score: "32/60",
    orank: 231,
    srank: 6,
    roll: "AS869110091",
    school: "St. Xaviers Public School",
    exam: "Asian Science Olympiad (ASO) 2023-24",
    prize: "",
  },
  {
    name: "Shreyashree Mishra",
    class: 10,
    score: "34/50",
    orank: 56,
    srank: 7,
    roll: "AM869110101",
    school: "Kendriya Vidyalaya No 4",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Akshita kashyap",
    class: 10,
    score: "23/50",
    orank: 78,
    srank: 8,
    roll: "AM869110104",
    school: "Kendriya Vidyalaya No 5",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
  {
    name: "Eashan Pattanayak",
    class: 10,
    score: "Abscent",
    orank: "",
    srank: "",
    roll: "MA869110346",
    school: "DAV Public School",
    exam: "Asian Mathematics Olympiad (AMO) 2023-24",
    prize: "",
  },
];

export default function queryData(roll) {
  if (roll === "" || roll === null) {
    return "Please enter a valid roll number";
  }
  const result = data.find((d) => d.roll === roll)
    ? data.find((d) => d.roll === roll)
    : `No certificate found for ${roll}`;
  return result;
}

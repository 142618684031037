import styled from "styled-components";

const Table = styled.div`
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  table {
    margin-bottom: 1rem;
  }
  th {
    text-align: right;
  }
  td {
    text-align: left;
  }
  a {
    font-size: 1.5rem;
    letter-spacing: 0.15rem;
    background: purple;
    padding: 0.66rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 25px;
    @media (max-width: 749px) {
      font-size: 0.8rem;
    }
  }
`;

export default function DataTable({ data }) {
  return (
    <div>
      <Table>
        <table cellSpacing="12">
          <tbody>
            <tr>
              <th>Name: </th>
              <td>{data.name}</td>
              <th>Score: </th>
              <td>{data.score}</td>
            </tr>
            <tr>
              <th>Roll: </th>
              <td>{data.roll}</td>
              <th>Class: </th>
              <td>{data.class}</td>
            </tr>
            <tr>
              <th>Exam:</th>
              <td>{data.exam}</td>
              <th>Rank: </th>
              <td>{data.orank} (Olympiad rank)</td>
            </tr>
            <tr>
              <th>School:</th>
              <td>{data.school}</td>
              <th>Rank: </th>
              <td>{data.srank} (School rank)</td>
            </tr>
            <tr>
              <th>Awards won:</th>
              <td>{data.prize}</td>
            </tr>
          </tbody>
        </table>
      </Table>
    </div>
  );
}

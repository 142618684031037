import React, { useRef } from "react";
import jsPDF from "jspdf";
import styled from "styled-components";

const CertificateGenerator = ({ user }) => {
  const canvasRef = useRef(null);
  const StyledBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 1.2em;
    margin: 1rem auto;
    cursor: pointer;
    background-color: #9a4ef1;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    text-decoration: none;
    font-weight: 300;
    font-size: 1em;
    color: #ffffff;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;

    &:hover {
      border-color: rgba(255, 255, 255, 1);
    }
  `;

  const generateCertificate = () => {
    if (user) {
      const img = new Image();
      img.src = "certificate_template.png";

      img.onload = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
        context.font = "24px Arial";
        context.fillText(user.name, 520, 390);
        context.fillText(user.school, 500, 428);
        context.font = "18px Arial";
        context.fillText(user.srank, 425, 522);
        context.fillText(user.orank, 830, 522);
        context.fillText(user.exam, 435, 558);
        context.fillText(user.class, 490, 490);
        context.fillText(user.roll, 680, 490);
        const dataUrl = canvas.toDataURL();
        const doc = new jsPDF({
          orientation: "landscape",
        });
        doc.addImage(
          dataUrl,
          "PNG",
          0,
          0,
          doc.internal.pageSize.getWidth(),
          doc.internal.pageSize.getHeight()
        );
        return doc.save(`${user.roll}.pdf`);
      };
    }
  };
  return (
    <div>
      <canvas
        hidden
        ref={canvasRef}
        width={1200}
        height={800}
        style={{ border: "1px solid black" }}
      />
      <StyledBtn onClick={generateCertificate}>Download Certificate</StyledBtn>
    </div>
  );
};

export default CertificateGenerator;
